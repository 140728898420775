import { useDispatch, useSelector } from "react-redux"
import TemplateContactInfo from "./TemplateContactInfo"
import ResumePreview from "../components/ResumePreview"
import './style.css';
import ProfessionalSummary from "./ProfessionalSummary";
import EducationView from "./EducationView";
import SkillsView from "./SkillsView";
import InteresetView from "./InteresetView";
import LanguageView from "./LanguageView";
import { TEMPLATES } from "../../../../util/Constants";

function Template3(params) {


    const contactdetails = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo)
    const designation = useSelector((state) => state.resumeReducer.persornalInfo.designation)
    const professionalSummary = useSelector((state) => state.resumeReducer.persornalInfo.professionalSummary)
    const workInfo = useSelector((state) => state.resumeReducer.workInfo)
    const skillInfo = useSelector((state) => state.resumeReducer.skillInfo)
    const educationInfo = useSelector((state) => state.resumeReducer.educationInfo)
    const languageInfo = useSelector((state) => state.resumeReducer.persornalInfo.language)
    const interest = useSelector((state) => {
        return state.resumeReducer.interest
    })

    return (<div>
        <ResumePreview>
            <TemplateContactInfo contactdetails={contactdetails} designation={designation} />
            <ProfessionalSummary heading={TEMPLATES.PROFESSIONAL_SUMMARY} summary={professionalSummary} />
            <EducationView heading={TEMPLATES.EDUCATION} educationData={educationInfo} />
            <SkillsView heading={TEMPLATES.SKILLS} skills={skillInfo} />
            <InteresetView heading={TEMPLATES.INTERESTS} skills={interest} />
            <LanguageView heading={TEMPLATES.KNOWN_LANGUAGES} languages={languageInfo} />
            <div style={{ height: '20mm' }} />
        </ResumePreview>
    </div>)
}

export default Template3