import TextWithIcon from '../../../../component/textWithIcon';
import Heading from './Heading';
import './style.css';
import styles from "./style";
import { TEMPLATES } from '../../../../util/Constants';

function ContactView({ contactdetails }) {
    let { firstName, lastName, email, phonenumber, address, website } = contactdetails
    return <>
        <Heading heading={TEMPLATES.CONTACT_ME} customStyle={{ paddingTop: '15mm' }} />
        {phonenumber && <TextWithIcon customTextStyle={{ ...styles.textStyle, }} icon='BsFillTelephoneFill' text={phonenumber} customContainerStyle={{ paddingBottom: 5 }} />}
        {email && <TextWithIcon customTextStyle={{ ...styles.textStyle, }} icon='BiLogoGmail' text={email} customContainerStyle={{ paddingBottom: 5 }} />}
        {address && <TextWithIcon customTextStyle={{ ...styles.textStyle, }} icon='BsFillHouseFill' text={address} customContainerStyle={{ paddingBottom: 5 }} />}
        {website && <TextWithIcon customTextStyle={{ ...styles.textStyle, }} icon='BsBrowserChrome' text={website} customContainerStyle={{ paddingBottom: 5 }} />}
    </>
}

export default ContactView