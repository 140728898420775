import React from "react"
import './style.css';
import { convertToMM } from "../../../../util/ScreenUtil";
import styles from "./style";
let paddingTop = '20mm'
let contentHeight = convertToMM(256)

function TemplateContactInfo({ contactdetails, designation }) {

    let { firstName, lastName, email, phonenumber, address, website } = contactdetails
    let name = firstName + " " + lastName
    name = name && name.toUpperCase()
    designation = designation && designation.toUpperCase()

    return (<div>
        <div style={{ paddingTop: paddingTop, height: contentHeight + paddingTop, backgroundColor: 'white' }}>
            <div style={{ height: contentHeight, backgroundColor: '#D6DDE6', alignItems: 'center', display: 'flex' }}>
                <div style={{ width: '100%', }}>
                    <p style={{ ...styles.textStyle, fontWeight: 'bolder', fontSize: 57, textAlign: 'center', }}>{name}</p>
                    <p style={{ ...styles.textStyle, fontWeight: 'normal', fontSize: 20, textAlign: 'center', marginTop: 10 }}>{designation}</p>
                </div>
            </div>
        </div>
    </div>)
}

export default TemplateContactInfo