import { useEffect, useState } from 'react';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { BsXLg, BsCalendarEvent } from 'react-icons/bs';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import EditorPlaceholder from '../../../component/editorPlaceholder';
import CalenderPlaceholder from '../../../component/calenderPlaceholder';
import DatePicker from 'react-date-picker';
import DateComponent from '../../../component/dateComponent';
import { isEmpty } from '../../../util/StringUtil';
import { INPUT_FIELDS } from '../../../util/Constants';

function WorkOrderListing(props) {

    const [shouldOpensStartDatePicker, setStartDatePickerStatus] = useState(false)
    const [shouldOpensEndDatePicker, setEndDatePickerStatus] = useState(false)

    useEffect(() => {
        let { companyName, jobTitle, city, country, startDate, endDate } = props
        setCompanyName_(companyName)
        setJobTitle_(jobTitle)
        setCity_(city)
        setCountry_(country)
        setStartDate_(startDate)
        setEndDate_(endDate)
    }, [])

    const setCompanyName_ = (value) => {
        props.setCompanyName && props.setCompanyName(value, props.index)
    }
    const setJobTitle_ = (value) => {
        props.setJobTitle && props.setJobTitle(value, props.index)
    }
    const setCity_ = (value) => {
        props.setCity && props.setCity(value, props.index)
    }
    const setCountry_ = (value) => {
        props.setCountry && props.setCountry(value, props.index)
    }
    const setStartDate_ = (value) => {
        props.setStartDate && props.setStartDate(value, props.index)
    }
    const setEndDate_ = (value) => {
        props.setEndDate && props.setEndDate(value, props.index)
    }

    const openStartDatePicker = (value = undefined) => {
        if (value) {
            setStartDatePickerStatus(value)
            return
        }
        setStartDatePickerStatus(!shouldOpensStartDatePicker)
    }

    const openEndDatePicker = (value = undefined) => {
        if (value) {
            setEndDatePickerStatus(value)
            return
        }
        setEndDatePickerStatus(!shouldOpensEndDatePicker)
    }

    const onDeletePress = () => {
        props.deleteItem && props.deleteItem(props.index)
    }

    const onEditorFocused = (value) => {
        props.setDescriptionModelStatusCallback && props.setDescriptionModelStatusCallback(value, props.index)
    }

    return (
        <>
            <>
                {props.index != 0 && <Row className="px-5 mt-3">
                    <Col>
                    </Col>
                    <Col md="auto" >
                        <BsXLg onClick={onDeletePress} style={{ width: 18, height: 18 }} className="mb-3 mx-5 me-1" />
                    </Col>
                </Row>}

                <Row className="g-2 px-5 pt-3">

                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                // required
                                placeholder={INPUT_FIELDS.COMPANY}
                                aria-label={INPUT_FIELDS.COMPANY}
                                defaultValue={props.companyName}
                                onChange={(event) => { setCompanyName_(event.target.value) }}
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Col>
                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                // required
                                placeholder={INPUT_FIELDS.JOB_TITLE}
                                aria-label={INPUT_FIELDS.JOB_TITLE}
                                defaultValue={props.jobTitle}
                                onChange={(event) => { setJobTitle_(event.target.value) }}
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="g-2 px-5">
                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                // required
                                placeholder={INPUT_FIELDS.CITY}
                                aria-label={INPUT_FIELDS.CITY}
                                defaultValue={props.city}
                                onChange={(event) => { setCity_(event.target.value) }}
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Col>
                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                // required
                                placeholder={INPUT_FIELDS.COUNTRY}
                                aria-label={INPUT_FIELDS.COUNTRY}
                                aria-describedby="basic-addon1"
                                defaultValue={props.country}
                                onChange={(event) => { setCountry_(event.target.value) }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="g-2 px-5">
                    <Col md>
                        <CalenderPlaceholder
                            onDateClicked={() => {
                                openStartDatePicker()
                            }}
                            placeholder={INPUT_FIELDS.START_DATE}
                            date={props.startDate}
                        />
                    </Col>
                    <Col md>
                        <CalenderPlaceholder
                            onDateClicked={() => {
                                openEndDatePicker()
                            }}
                            placeholder={INPUT_FIELDS.END_DATE}
                            endDateCallback={setEndDate_}
                            setEndDate={setEndDate_}
                            isEndDate={true}
                            date={props.endDate}
                        />
                    </Col>
                </Row>

                <EditorPlaceholder
                    placeholder={INPUT_FIELDS.DESCRIPTION}
                    description={props.description}
                    onEditorFocused={onEditorFocused} />
            </>

            <DateComponent
                maxDate={props.endDate}
                show={shouldOpensStartDatePicker}
                onHide={openStartDatePicker}
                onDateClicked={(value) => {
                    setStartDate_(value)
                    openStartDatePicker()
                }} />

            <DateComponent
                show={shouldOpensEndDatePicker}
                minDate={props.startDate}
                onHide={(shouldHide, value) => {
                    openEndDatePicker(shouldHide)
                    setEndDate_(value)
                }}
                onDateClicked={(value) => {
                    setEndDate_(value)
                    openEndDatePicker()
                }} />

        </>);
}

export default WorkOrderListing;