import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../../data/store/actions/ProgressActions';
import { useNavigate } from 'react-router-dom';
import { SKILLS_ROUTE } from '../../../AppRouter';
import AppHeader from '../../../component/appHeader';
import EducationDetails from './EducationDetails';
import { addEducationCity, addEducationDescription, addEducationEndDate, addEducationStartDate, addEducationUniversity, addMoreEducation, deleteEducation } from '../../../data/store/actions/ResumeActions';
import AppEditor from '../../../component/editor';
import { DimensionContext } from '../../../util/DimensionContext';
import { BUTTONS, HEADERS } from '../../../util/Constants';

function EducationDetail() {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [isValid, setValidationStatus] = useState(false)
    const educationInfo = useSelector((state) => state.resumeReducer.educationInfo)
    const [shouldShowDescriptionModel, setDescriptionModelStatus] = useState(false)
    const [indexEditorToBeOpenedFor, setIndexEditorToBeOpenedFor] = useState(-1)
    const width = useContext(DimensionContext);

    const setCity = (value, index) => {
        dispatch(addEducationCity(value, index))
    }
    const setUniversity = (value, index) => {
        dispatch(addEducationUniversity(value, index))
    }
    const setStartDate = (value, index) => {
        dispatch(addEducationStartDate(value, index))
    }
    const setEndDate = (value, index) => {
        dispatch(addEducationEndDate(value, index))
    }

    const deleteItem = (index) => {
        if (!index) return
        dispatch(deleteEducation(index))
    }

    const addMore = () => {
        dispatch(addMoreEducation())
    }

    useEffect(() => {
        dispatch(updateProgress(66))
    })

    const navigateToSkillsScreen = () => {
        navigate(SKILLS_ROUTE)
    }

    const goBack = () => {
        navigate(-1)
    }

    const setDescription = (value, index) => {
        dispatch(addEducationDescription(value, index))
    }

    const setDescriptionModelStatusCallback = (value, indexEditorToBeOpenedFor) => {
        setDescriptionModelStatus(value)
        setIndexEditorToBeOpenedFor(indexEditorToBeOpenedFor)
    }

    const onSubmit = (event) => {
        const form = event.currentTarget;
        setValidationStatus(!form.checkValidity());
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            navigateToSkillsScreen()
        }
    };

    return (
        <Container fluid className={width < 800 ? "p2" : "p-5"}>
            <Form noValidate validated={isValid} onSubmit={onSubmit} >
                <AppHeader title={HEADERS.EDUCATION_DETAILS} onBack={goBack} />
                {educationInfo.map((item, index) => {
                    return <EducationDetails
                        city={item.city}
                        university={item.university}
                        startDate={item.startDate}
                        endDate={item.endDate}
                        index={index}
                        deleteItem={deleteItem}
                        setCity={setCity}
                        setUniversity={setUniversity}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        description={item.description}
                        setDescriptionModelStatusCallback={setDescriptionModelStatusCallback}
                    />
                })}
                <Button className='ms-5' variant="primary" onClick={addMore}>
                    {BUTTONS.ADD_MORE}
                </Button>
            </Form>
            {shouldShowDescriptionModel && <AppEditor
                show={shouldShowDescriptionModel}
                description={educationInfo[indexEditorToBeOpenedFor]?.description}
                savePressed={(value) => {
                    setDescription(value, indexEditorToBeOpenedFor)
                    setDescriptionModelStatus(false)
                }}
                onHide={() => { setDescriptionModelStatus(false) }}
            />}
        </Container>
    );
}

export default EducationDetail;