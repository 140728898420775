import { useState } from 'react';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './style.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function DateComponent(props) {
    let { onDateClicked, maxDate = new Date(), minDate = new Date(1900, 1, 1), onHide } = props
    const [value, onChange] = useState(new Date());
    const [currentWorkValue, setCurrentWorkValue] = useState(false);


    const onDateChanged = (value) => {
        onChange(value)
        onDateClicked && onDateClicked(value)
    }

    const onHideClick = () => {
        onHide && onHide(false)
    }

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton onHide={onHideClick}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Date Selection
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Calendar
                    maxDate={new Date(maxDate)}
                    minDate={new Date(minDate)}
                    onChange={onDateChanged} value={value} />
            </Modal.Body>
        </Modal>
    );
}

export default DateComponent