import React from "react"
import { Col, Row } from "react-bootstrap";
import './style.css';
import Heading from "./Heading";
import styles from "./style";
import VerticalLine, { VERTICAL_LINE_CONTENT_SPACING } from "./VerticalLine";

function ProfessionalSummary({ heading, summary, divID = undefined }) {
    return (
        <Col
            id={divID}
            style={{ ...styles.horizontalPadding }}>
            <Heading heading={heading} customStyle={{ ...styles.headingVerticalMargin }} />
            <Row>
                <Col xs={1} style={{ maxWidth: VERTICAL_LINE_CONTENT_SPACING, ...styles.removeExtraSpacing }}>
                    <VerticalLine />
                </Col>
                <Col >
                    {summary && <p style={{ ...styles.removeExtraSpacing, fontSize: 14, fontWeight: 'normal', ...styles.textStyle, ...styles.secondaryColor }} class="textOverlapFix">{summary} </p>}
                </Col>
            </Row>
        </Col>
    )
}

export default ProfessionalSummary