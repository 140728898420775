import { Button, Col, Row } from 'react-bootstrap';
import { BsArrowLeftShort } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { BsEyeglasses } from "react-icons/bs";
import { SELECTED_TEMPLATE } from '../../AppRouter';
import { useNavigate } from 'react-router-dom';
import { BsDownload, BsCloudDownload, BsCloudUpload } from "react-icons/bs";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef, useState } from 'react';
import { useSaveUserResume } from '../../ui/hooks/useSaveUserResume';
import ResumeDownloaderUploader from '../resumeDownloaderUploader';
import { FaRegSave } from "react-icons/fa";
import { IoSaveOutline } from "react-icons/io5";
import { MdPreview } from "react-icons/md";
import { BiShow } from "react-icons/bi";
import { CiMoneyCheck1 } from "react-icons/ci";
import { GrDownload } from "react-icons/gr";

function AppHeader({ onNext, onBack, title, isPreviewEnabled = true, isNextEnabled = true, isBackEnabled = true, isDownloadEnabled = false, onClickDownload = undefined, isLoadButtonEnabled = false, onLoad, isSaveEnabled = true }) {

    const navigate = useNavigate();
    const { saveResume } = useSaveUserResume();
    const [popUpStatus, setPopUpStatus] = useState(false)

    const onNextPress = (parms) => {
        onNext && onNext(parms)
    };

    const onBackpRess = (parms) => {
        onBack && onBack(parms)
    }

    const onPreviewClick = (parms) => {
        navigate(SELECTED_TEMPLATE)
    }

    const onDownloadClick = (parms) => {
        onClickDownload && onClickDownload()
    }

    const onLoadClick = () => {
        onLoad && onLoad()
    }

    const onUploadClick = () => {
        // saveResume()
        setPopUpStatus(true)
    }

    return (
        <>
            <Row className="px-5 pt-3" >
                <Col >
                    <h1 className='mb-3'>{title}</h1>
                </Col>
                <Col md="auto" >
                    {isSaveEnabled && <IoSaveOutline onClick={onUploadClick} style={{ width: 40, height: 40 }} className="me-3 p-2" />}
                    {isLoadButtonEnabled && <GrDownload onClick={onLoadClick} style={{ width: 40, height: 40 }} className="me-3 p-2" />}
                    {isPreviewEnabled && <CiMoneyCheck1 onClick={onPreviewClick} style={{ width: 40, height: 40 }} className="me-3 p-1" />}
                    {isBackEnabled && <BsArrowLeftShort onClick={onBackpRess} style={{ width: 40, height: 40 }} className="me-3" />}
                    {isNextEnabled && <Button onClick={onNextPress} variant="link" type="submit" className='p-0' >
                        <BsArrowRightShort style={{ color: "#00005c", width: 40, height: 40 }} />
                    </Button>}
                    {isDownloadEnabled && <BsDownload onClick={onDownloadClick} style={{ width: 40, height: 40 }} className="me-3 p-2" />}
                </Col>

                <ResumeDownloaderUploader
                    isUploader={true}
                    show={popUpStatus}
                    savePressed={() => {
                        setPopUpStatus(false)
                    }}
                    onHide={() => { setPopUpStatus(false) }}
                />
            </Row>
        </>
    );
}

export default AppHeader;