export const RESET_RESUME = 'RESET_RESUME'
export const SELECTED_RESUME_TEMPLATE_ID = 'SELECTED_RESUME_TEMPLATE_ID'
export const UPDATE_RESUME_WITH_STORED_DATA = 'UPDATE_RESUME_WITH_STORED_DATA'

export const PERSONAL_INFO_PROFESSIONAL_DESIGNATION = 'PERSONAL_INFO_PROFESSIONAL_DESIGNATION'
export const PERSONAL_INFO_PROFESSIONAL_SUMMARY = 'PERSONAL_INFO_PROFESSIONAL_SUMMARY'

export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL'
export const CONTACT_DETAIL_FIRST_NAME = 'CONTACT_DETAIL_FIRST_NAME'
export const CONTACT_DETAIL_LAST_NAME = 'CONTACT_DETAIL_LAST_NAME'
export const CONTACT_DETAIL_EMAIL = 'CONTACT_DETAIL_EMAIL'
export const CONTACT_DETAIL_PHONE_NUMBER = 'CONTACT_DETAIL_PHONE_NUMBER'
export const CONTACT_DETAIL_WEBSITE = 'CONTACT_DETAIL_WEBSITE'
export const CONTACT_DETAIL_ADDRESS = 'CONTACT_DETAIL_ADDRESS'


export const UPDATE_WORK_DETAIL = 'UPDATE_WORK_DETAIL'
export const ADD_MORE_WORK_DETAIL = 'ADD_MORE_WORK_DETAIL'
export const DELETE_WORK_ORDER = 'DELETE_WORK_ORDER'
export const WORK_ORDER_COUNTRY = 'WORK_ORDER_COUNTRY'
export const WORK_ORDER_CITY = 'WORK_ORDER_CITY'
export const WORK_ORDER_JOBTITLE = 'WORK_ORDER_JOBTITLE'
export const WORK_ORDER_COMPANY = 'WORK_ORDER_COMPANY'
export const WORK_ORDER_START_DATE = 'WORK_ORDER_START_DATE'
export const WORK_ORDER_END_DATE = 'WORK_ORDER_END_DATE'
export const WORK_ORDER_DESCRIPTION = 'WORK_ORDER_DESCRIPTION'

export const ADD_MORE_EDUCATION = 'ADD_MORE_EDUCATION'
export const DELETE_EDUCATION = 'DELETE_EDUCATION'
export const EDUCATION_UNIVERSITY = 'EDUCATION_UNIVERSITY'
export const EDUCATION_CITY = 'EDUCATION_CITY'
export const EDUCATION_START_DATE = 'EDUCATION_START_DATE'
export const EDUCATION_END_DATE = 'EDUCATION_END_DATE'
export const EDUCATION_DESCRIPTION = 'EDUCATION_DESCRIPTION'

export const SKILL_ADD_MORE = 'SKILL_ADD_MORE'
export const SKILL_ADD = 'SKILL_ADD'

export const ACHIVEMENTS_ADD_MORE = 'ACHIVEMENTS_ADD_MORE'
export const ACHIVEMENTS_ADD = 'ACHIVEMENTS_ADD'

export const LANGUAGE_ADD_MORE = 'LANGUAGE_ADD_MORE'
export const LANGUAGE_ADD = 'LANGUAGE_ADD'

export const INTEREST_ADD_MORE = 'INTEREST_ADD_MORE'
export const INTEREST_ADD = 'INTEREST_ADD'

export const resetResume = () => ({
    type: RESET_RESUME,
})

export const updateResumeWithStoredData = (resume) => ({
    type: UPDATE_RESUME_WITH_STORED_DATA,
    resume: resume
})

export const selectedTemplate = (templateId) => ({
    type: SELECTED_RESUME_TEMPLATE_ID,
    templateId: templateId
})

// PERSONAL INFO
export const updatePersonalInfoDesignation = (designation) => ({
    type: PERSONAL_INFO_PROFESSIONAL_DESIGNATION,
    designation: designation
})

export const updatePersonalInfoSummary = (professionalSummary) => ({
    type: PERSONAL_INFO_PROFESSIONAL_SUMMARY,
    professionalSummary: professionalSummary
})

// Contact Detail
export const updateContactDetail = (value) => ({
    type: UPDATE_CONTACT_DETAIL,
    payload: value
})

export const updateContactDetailFirstName = (firstName) => ({
    type: CONTACT_DETAIL_FIRST_NAME,
    firstName: firstName
})

export const updateContactDetailLastName = (lastName) => ({
    type: CONTACT_DETAIL_LAST_NAME,
    lastName: lastName
})

export const updateContactDetailEmail = (email) => ({
    type: CONTACT_DETAIL_EMAIL,
    email: email
})

export const updateContactDetailPhoneNumber = (phonenumber) => ({
    type: CONTACT_DETAIL_PHONE_NUMBER,
    phonenumber: phonenumber
})

export const updateContactDetailWebsite = (website) => ({
    type: CONTACT_DETAIL_WEBSITE,
    website: website
})

export const updateContactDetailAddress = (address) => ({
    type: CONTACT_DETAIL_ADDRESS,
    address: address
})

// Work Detail
export const updateWorkDetail = (value, index = 0) => ({
    type: UPDATE_WORK_DETAIL,
    payload: value,
    index: index
})
export const addMoreWorkOrder = () => ({
    type: ADD_MORE_WORK_DETAIL,
})
export const addWorkOrderCountry = (countryName, index) => ({
    type: WORK_ORDER_COUNTRY,
    countryName: countryName,
    index: index
})
export const addWorkOrderCity = (cityName, index) => ({
    type: WORK_ORDER_CITY,
    cityName: cityName,
    index: index
})
export const addWorkOrderCompany = (companyName, index) => ({
    type: WORK_ORDER_COMPANY,
    companyName: companyName,
    index: index
})
export const addWorkOrderJobtitle = (jobTitle, index) => ({
    type: WORK_ORDER_JOBTITLE,
    jobTitle: jobTitle,
    index: index
})
export const addWorkOrderStartDate = (startDate, index) => ({
    type: WORK_ORDER_START_DATE,
    startDate: startDate,
    index: index
})
export const addWorkOrderEndDate = (endDate, index) => ({
    type: WORK_ORDER_END_DATE,
    endDate: endDate,
    index: index
})
export const addWorkOrderDescription = (description, index) => ({
    type: WORK_ORDER_DESCRIPTION,
    description: description,
    index: index
})
export const deleteWorkOrder = (index) => ({
    type: DELETE_WORK_ORDER,
    index: index
})

// Education Detail
export const addMoreEducation = () => ({
    type: ADD_MORE_EDUCATION,
})

export const deleteEducation = (index) => ({
    type: DELETE_EDUCATION,
    index: index
})

export const addEducationUniversity = (university, index) => ({
    type: EDUCATION_UNIVERSITY,
    index: index,
    university: university,
})

export const addEducationCity = (city, index) => ({
    type: EDUCATION_CITY,
    index: index,
    city: city,
})
export const addEducationStartDate = (startDate, index) => ({
    type: EDUCATION_START_DATE,
    startDate: startDate,
    index: index
})
export const addEducationEndDate = (endDate, index) => ({
    type: EDUCATION_END_DATE,
    endDate: endDate,
    index: index
})
export const addEducationDescription = (description, index) => ({
    type: EDUCATION_DESCRIPTION,
    description: description,
    index: index
})

// SKILLS -----

export const addMoreSkill = () => ({
    type: SKILL_ADD_MORE,
})

export const addSkill = (skill, index) => ({
    type: SKILL_ADD,
    skill: skill,
    index: index
})

// ACHIVEMENTS -----

export const addMoreAchivement = () => ({
    type: ACHIVEMENTS_ADD_MORE,
})

export const addAchivements = (achivement, index) => ({
    type: ACHIVEMENTS_ADD,
    achivement: achivement,
    index: index
})

// Langugae -----

export const addMoreLanguage = () => ({
    type: LANGUAGE_ADD_MORE,
})

export const addLanguage = (language, index) => ({
    type: LANGUAGE_ADD,
    language: language,
    index: index
})

// INTEREST -----

export const addMoreInterest = () => ({
    type: INTEREST_ADD_MORE,
})

export const addInterest = (interest, index) => ({
    type: INTEREST_ADD,
    interest: interest,
    index: index
})