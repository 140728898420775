import './style.css';
import styles from "./style";
import { Col } from 'react-bootstrap';
import Heading from './Heading';

function ProfessionalSummary({ heading, summary, divID = undefined }) {
    heading = heading.toUpperCase()
    return (
        <Col id={divID} style={{ marginTop: '3mm' }}>
            <Heading heading={"Professional summary"} />
            <p class="textOverlapFix" style={{ fontWeight: '400', fontSize: 14, color: styles.secondaryColor, align: 'left', ...styles.removeExtraSpacing, fontFamily: styles.textStyle, ...styles.removeExtraSpacing, ...styles.horizontalPadding_20mm, }}>{summary}</p>
        </Col>)
}

export default ProfessionalSummary
