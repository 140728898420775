import Heading from './Heading';
import './style.css';
import styles from "./style";
import { HEADERS, TEMPLATES } from '../../../../util/Constants';

function ProfileView({ professionalSummary }) {
    return <>
        <Heading heading={TEMPLATES.PROFILE_SUMMARY} />
        {professionalSummary && <p style={{ ...styles.textStyle, fontWeight: 'normal', fontSize: 16, textAlign: 'left' }}>{professionalSummary}</p>}
    </>
}

export default ProfileView