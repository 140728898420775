export const isEmpty = (obj) => {
    if (obj == undefined || obj == [] || obj == {} || obj === undefined || obj === "" || obj.length === 0) return true
    if (typeof obj === 'string' || obj instanceof String) {
        return false
    }
    if (typeof obj === 'date' || obj instanceof Date) {
        return false
    }
    let isPresnt = true
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            isPresnt = false
    }
    return isPresnt
}

export const isValidEmail = (inputValue) => {
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(inputValue)) {
        return false
    }
    return true
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}