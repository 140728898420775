import ReactGA from 'react-ga4'

const DOWNLOAD_RESUME = 'Download Resume'
const CREATE_RESUME = 'Create Resume'
const SAVE_RESUME = 'Save Resume'

export const initializeAnalytics = () => {
    // ReactGA.initialize('8348519032');
    ReactGA.initialize('G-F3GE9MSSVE');
}

export const isDevEnv = () => {
    return process.env.REACT_APP_ENVIORNMENT === 'DEV';
}

export const trackEvent = (label, category) => {
    if (isDevEnv()) return;
    // console.log("==============")
    // console.log("==============")
    // console.log("==============")
    // console.log("download event : ", label)
    // let temp = isDevEnv() ? "_dev" : "_prod"
    ReactGA.event({
        label: label,
        category: category,
    })
}

export const haveStartedCreatingResumeEvent = (email) => {
    trackEvent(email, CREATE_RESUME)
}

export function downlaodResumeEvent(email) {
    trackEvent(email, DOWNLOAD_RESUME)
}

export function saveResumeEvent(email) {
    trackEvent(email, SAVE_RESUME)
}