const styles = {
    switchStyle: {
        width: '100%',
        borderRadius: 5,
        border: '1px solid #dee2e6',
        color: "#212529",
        padding: '0.375rem 0.75rem',
        borderColor: '#dee2e6',
        fontSize: '1rem',
        fontWeight: '400',
    },
    disabledSwitchStyle: {
        width: '100%',
        borderRadius: 5,
        border: '1px solid #dee2e6',
        color: "#dee2e6",
        padding: '0.375rem 0.75rem',
        borderColor: '#dee2e6',
        fontSize: '1rem',
        fontWeight: '400',
    }
}

export default styles