import { Col, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import "react-datepicker/dist/react-datepicker.css";
import { isEmpty } from '../../util/StringUtil';

function EditorPlaceholder(props) {

    const onEditorFocused = () => {
        props.onEditorFocused && props.onEditorFocused(true)
    }

    let description = isEmpty(props.description) ? props.placeholder : props.description

    return (
        <>
            <Row className="g-2 px-5">
                <Col md>
                    <InputGroup className="mb-3">
                        <div onClick={onEditorFocused}
                            style={{
                                width: '100%', borderRadius: 5,
                                border: '1px solid #dee2e6',
                                color: description === props.placeholder ? "#495057" : "#212529",
                                padding: '0.375rem 0.75rem', borderColor: '#dee2e6', fontSize: '1rem', fontWeight: '400',
                            }}
                            dangerouslySetInnerHTML={{ __html: description }} />
                    </InputGroup>
                </Col>
            </Row>
        </>);
}

export default EditorPlaceholder;