import React from "react"
import { CURRENT } from "../../../../component/calenderPlaceholder"

const DateComponent = ({ startDate, endDate, customStyle, shouldShowYearOnly }) => {
    let _startDate = createFormattedDate(startDate, shouldShowYearOnly)
    let _endDate = endDate === CURRENT ? CURRENT : createFormattedDate(endDate, shouldShowYearOnly)
    return <p class="textOverlapFix" style={{ verticalAlign: 'middle', display: 'table-cell', ...customStyle }}>{`${_startDate} - ${_endDate}`}</p>
}

function createFormattedDate(date, shouldShowYearOnly) {
    if (shouldShowYearOnly) {
        return new Date(date).getFullYear()
    }
    return (new Date(date).getMonth() + 1) + "/" + new Date(date).getFullYear()
}

export default DateComponent