import { Col, Row } from "react-bootstrap"
import styles from './style'
import Heading from "./Heading"
import DateComponent from "../components/DateComponent";

function EducationView({ heading, educationData }) {
    return (
        <Col style={{ ...styles.horizontalMargin }}>
            <Heading icon="TbSchool" heading={heading} customStyle={{ ...styles.headingVerticalMargin }} />
            {
                educationData.map((item) => {
                    return <EductionItem item={item} />
                })
            }
        </Col>
    )
}

const EductionItem = ({ item }) => {
    return (
        <Row className="mb-4" style={{ ...styles.removeExtraSpacing }}>
            <Col style={{ ...styles.removeExtraSpacing }}>
                <EducationInfoItem item={item} />
            </Col>
            <Col style={{ ...styles.removeExtraSpacing }}>
                <DescriptionView item={item} />
            </Col>
        </Row>)
}

const EducationInfoItem = ({ item }) => {
    let { university, startDate, endDate, city } = item
    return (
        <div style={{ ...styles.removeExtraSpacing }} >
            <p class="textOverlapFix" style={{ ...styles.textStyle, ...styles.primaryColor, ...styles.removeExtraSpacing, fontWeight: 'bold', fontSize: 16 }}>{university.toUpperCase()}</p>
            <DateComponent shouldShowYearOnly startDate={startDate} endDate={endDate} customStyle={{ ...styles.textStyle, ...styles.tertiaryColor, fontWeight: 'normal', fontSize: 14 }} />
            <p class="textOverlapFix" style={{ ...styles.textStyle, ...styles.tertiaryColor, ...styles.removeExtraSpacing, fontWeight: 'normal', fontSize: 14 }}>{city.toUpperCase()}</p>
        </div>)
}

const DescriptionView = ({ item }) => {
    let { description } = item

    return (
        <div style={{ ...styles.removeExtraSpacing }}  >
            <p className="mt-1" dangerouslySetInnerHTML={{ __html: description }} style={{ ...styles.removeExtraSpacing, ...styles.textStyle, ...styles.primaryColor, ...styles.removeExtraSpacing, fontWeight: 'normal', fontSize: 14 }} />
        </div>)
}

export default EducationView