const COMMON = {
    ACHIVEMENTS: "Achievements",
    EDUCATION_DETAILS: "Education details",
    LANGUAGE: "Language",
    ADD_MORE: "Add more",
    KNOWN_LANGUAGES: "Known Languages",
    UNIVERSITY_COLLEGE_SCHOOL: "University/College/School",
    DEGREE: "Degree",
    CITY: "City",
    START_DATE: "Start date",
    END_DATE: "End date",
    DESCRIPTION: "Description",
    SELECT_TEMPLATE: "Select Template",
    FOR: 'For',
    EXPERIENCE: "EXPERIENCE",
    STUDENT: "Student",
    PROFESSIONAL_SUMMARY: "Professional Summary",
    DESIGNATION: "Designation",
    SUMMARY: "Summary",
    SKILLS: "Skills",
    SKILL: "Skill",
    CONTACT_DETAILS: "Contact details",
    FIRSTNAME: "Firstname",
    LASTNAME: "Lastname",
    EMAIL: "Email",
    PHONE_NUMBER: "Phonenumber",
    WEBSITE: "Website",
    ADDRESS: "Address",
    WORK_DETIALS: "Work details",
    COMPANY: "Company",
    JOB_TITLE: "Job title",
    COUNTRY: "Country",
    EDUCATION: "Education",
    WORK_SUMMARY: "Work Summary",
    PROFILE_SUMMARY: "Profile Summary",
    CONTACT_ME: "Contact Me",
    LANGUAGES: "Languages",
    INTERESTS: "Interests",
    EDUCATION_SUMMARY: "Education Summary",
    WORK_EXPERIENCE: "Work Experience",
    EXPERIENCE: "Experience",
    ACHIVEMENT: "Achievement",
}

const TEMPLATES = {
    PROFESSIONAL_SUMMARY: COMMON.PROFESSIONAL_SUMMARY,
    SKILLS: COMMON.SKILLS,
    EDUCATION: COMMON.EDUCATION,
    WORK_SUMMARY: COMMON.WORK_SUMMARY,
    PROFILE_SUMMARY: COMMON.PROFILE_SUMMARY,
    CONTACT_ME: COMMON.CONTACT_ME,
    LANGUAGES: COMMON.LANGUAGES,
    INTERESTS: COMMON.INTERESTS,
    KNOWN_LANGUAGES: COMMON.KNOWN_LANGUAGES,
    EDUCATION_SUMMARY: COMMON.EDUCATION_SUMMARY,
    ACHIVEMENTS: COMMON.ACHIVEMENTS,
    WORK_EXPERIENCE: COMMON.WORK_EXPERIENCE,
    EXPERIENCE: COMMON.EXPERIENCE,
}

const HEADERS = {
    ACHIVEMENTS: COMMON.ACHIVEMENTS,
    EDUCATION_DETAILS: COMMON.EDUCATION_DETAILS,
    KNOWN_LANGUAGES: COMMON.KNOWN_LANGUAGES,
    SELECT_TEMPLATE: COMMON.SELECT_TEMPLATE,
    PROFESSIONAL_SUMMARY: COMMON.PROFESSIONAL_SUMMARY,
    SKILLS: COMMON.SKILLS,
    CONTACT_DETAILS: COMMON.CONTACT_DETAILS,
    WORK_DETIALS: COMMON.WORK_DETIALS,
}

const INPUT_FIELDS = {
    LANGUAGE: COMMON.LANGUAGE,
    UNIVERSITY_COLLEGE_SCHOOL: COMMON.UNIVERSITY_COLLEGE_SCHOOL,
    DEGREE: COMMON.DEGREE,
    CITY: COMMON.CITY,
    START_DATE: COMMON.START_DATE,
    END_DATE: COMMON.END_DATE,
    DESCRIPTION: COMMON.DESCRIPTION,
    DESIGNATION: COMMON.DESIGNATION,
    SUMMARY: COMMON.SUMMARY,
    SKILL: COMMON.SKILL,
    FIRSTNAME: COMMON.FIRSTNAME,
    LASTNAME: COMMON.LASTNAME,
    EMAIL: COMMON.EMAIL,
    PHONE_NUMBER: COMMON.PHONE_NUMBER,
    WEBSITE: COMMON.WEBSITE,
    ADDRESS: COMMON.ADDRESS,
    COMPANY: COMMON.COMPANY,
    JOB_TITLE: COMMON.JOB_TITLE,
    COUNTRY: COMMON.COUNTRY,
    ACHIVEMENT: COMMON.ACHIVEMENT,
}

const BUTTONS = {
    ADD_MORE: COMMON.ADD_MORE,
}

export {
    COMMON, HEADERS, INPUT_FIELDS, BUTTONS, TEMPLATES
}