import { Col, Row } from "react-bootstrap"
import styles from "./style";
import './style.css';
import TextWithIcon from "../../../../component/textWithIcon";

function ContactInfo({ contactdetails, designation, divID = undefined }) {
    let { firstName, lastName, email, phonenumber, address, website } = contactdetails
    let name = firstName + " " + lastName
    name = name && name.toUpperCase()
    designation = designation && designation.toUpperCase()

    return (
        <Col id={divID} style={{
            paddingTop: '10mm',
        }}>
            <Row style={{ ...styles.primaryBGColor, ...styles.removeExtraSpacing, ...styles.horizontalPadding, padding: 20 }}>
                <Col xs={7} style={{ alignItems: 'center', display: 'flex', ...styles.removeExtraSpacing, }}>
                    <Col style={{ ...styles.removeExtraSpacing }}>
                        <p style={{ ...styles.removeExtraSpacing, ...styles.textStyle, ...styles.primaryColor, fontSize: 38, fontWeight: 'bold' }}>{name}</p>
                        <p style={{ ...styles.removeExtraSpacing, ...styles.textStyle, ...styles.secondaryColor, fontSize: 14, fontWeight: 'bold' }}>{designation}</p>
                    </Col>
                </Col>
                <Col style={{ ...styles.removeExtraSpacing, alignItems: 'center', display: 'flex' }} >
                    <Col style={{ ...styles.removeExtraSpacing, }}>
                        <Row
                            // xs={1} md={2} 
                            style={{ ...styles.removeExtraSpacing, }}>
                            <Col style={{ ...styles.removeExtraSpacing, }} >
                                {phonenumber && <TextWithIcon icon={"CiPhone"} text={phonenumber}
                                    customTextStyle={{
                                        fontSize: 12, marginLeft: 10, ...styles.textStyle, fontWeight: 'normal', ...styles.secondaryColor
                                    }} iconSize={16} iconStyle={{ ...styles.secondaryColor }} />}
                                {email && <TextWithIcon
                                    customContainerStyle={{ marginTop: 5 }}
                                    icon={"CiMail"} text={email} customTextStyle={{
                                        fontSize: 12, marginLeft: 10, ...styles.textStyle, fontWeight: 'normal', ...styles.secondaryColor
                                    }} iconSize={16} iconStyle={{ ...styles.secondaryColor }} />}
                                {address && <TextWithIcon
                                    customContainerStyle={{ marginTop: 5 }}
                                    icon={"CiLocationArrow1"} text={address} customTextStyle={{
                                        fontSize: 12, marginLeft: 10, ...styles.textStyle, fontWeight: 'normal', ...styles.secondaryColor
                                    }} iconSize={16} iconStyle={{ ...styles.secondaryColor }} />}
                                {website && <TextWithIcon
                                    customContainerStyle={{ marginTop: 5 }}
                                    icon={"PiGoogleChromeLogoThin"} text={website} customTextStyle={{
                                        fontSize: 12, marginLeft: 10, ...styles.textStyle, fontWeight: 'normal', ...styles.secondaryColor
                                    }} iconSize={16} iconStyle={{ ...styles.secondaryColor }} />}
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row >
        </Col >
    )
}

export default ContactInfo