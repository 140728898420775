import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../../data/store/actions/ProgressActions';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../../../component/appHeader';
import { addAchivements, addLanguage, addMoreAchivement, addMoreInterest, addMoreLanguage } from '../../../data/store/actions/ResumeActions';
import EditorPlaceholder from '../../../component/editorPlaceholder';
import AppEditor from '../../../component/editor';
import { DimensionContext } from '../../../util/DimensionContext';
import { BUTTONS, HEADERS, INPUT_FIELDS } from '../../../util/Constants';

function Achievements() {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [shouldShowDescriptionModel, setShowDescriptionModel] = useState(false)
    const [indexEditorToBeOpenedFor, setIndexEditorToBeOpenedFor] = useState(-1)
    const achievementsInfo = useSelector((state) => state.resumeReducer.achievementsInfo)
    const languagesInfo = useSelector((state) => state.resumeReducer.persornalInfo.language)
    const width = useContext(DimensionContext);

    useEffect(() => {
        dispatch(updateProgress(100))
    })

    const navigateToWorkDetailScreen = () => {
        // navigate(WORK_DETAIL_ROUTE)
    }

    const goBack = () => {
        navigate(-1)
    }

    const setAchivement = (achivement, index) => {
        dispatch(addAchivements(achivement, index))
    }

    const addMore = () => {
        dispatch(addMoreAchivement())
    }

    const addMoreLanguage_ = () => {
        dispatch(addMoreLanguage())
    }

    const onEditorFocused = (value, index) => {
        setShowDescriptionModel(true)
        setIndexEditorToBeOpenedFor(index)
    }

    const setLanguage = (language, index) => {
        dispatch(addLanguage(language, index))
    }

    return (
        <Container fluid className={width < 800 ? "p2" : "p-5"}>
            <AppHeader title={HEADERS.ACHIVEMENTS} onBack={goBack} isNextEnabled={false} />
            <div style={{ height: 10 }} />
            {achievementsInfo.map((item, index) => {
                return (<EditorPlaceholder
                    placeholder={INPUT_FIELDS.ACHIVEMENT}
                    description={item}
                    onEditorFocused={() => onEditorFocused(item, index)} />)
            })}
            <Button className='ms-5 mb-5' variant="primary" onClick={addMore}>
                {BUTTONS.ADD_MORE}
            </Button>
            <AppHeader title={HEADERS.KNOWN_LANGUAGES} isNextEnabled={false} isBackEnabled={false} isSaveEnabled={false} isPreviewEnabled={false} />
            {languagesInfo.map((item, index) => {
                return <InputGroup className="mb-3 px-5">
                    <Form.Control
                        required
                        placeholder={INPUT_FIELDS.LANGUAGE}
                        aria-label={INPUT_FIELDS.LANGUAGE}
                        defaultValue={item.language}
                        onChange={(event) => { setLanguage(event.target.value, index) }}
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
            })}
            <Button className='ms-5 mb-5' variant="primary" onClick={addMoreLanguage_}>
                {BUTTONS.ADD_MORE}
            </Button>
            <AppEditor
                show={shouldShowDescriptionModel} zz
                savePressed={(value) => {
                    setAchivement(value, indexEditorToBeOpenedFor)
                    setShowDescriptionModel(false)
                }}
                onHide={() => { setShowDescriptionModel(false) }}
            />
        </Container>
    );
}

export default Achievements;