import { useDispatch, useSelector } from "react-redux"
import ResumePreview from "../components/ResumePreview"
import './style.css';
import ProfessionalSummary from "./ProfessionalSummary";
import ContactInfo from "./ContactInfo";
import WorkSummary from "./WorkSummary";
import EducationSummary from "./EducationSummary";
import SkillsView from "./SkillsView";
import AchivementView from "./AchivementView";
import PreviewParent from "../components/previewParent";
import PreviewParent_ from "../components/previewParent/PreviewParent_";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { TEMPLATES } from "../../../../util/Constants";
import { downlaodResumeEvent } from "../../../../util/Analytics";

function Template4(params) {


    const contactdetails = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo)
    const designation = useSelector((state) => state.resumeReducer.persornalInfo.designation)
    const professionalSummary = useSelector((state) => state.resumeReducer.persornalInfo.professionalSummary)
    const workInfo = useSelector((state) => state.resumeReducer.workInfo)
    const skillInfo = useSelector((state) => state.resumeReducer.skillInfo)
    const educationInfo = useSelector((state) => state.resumeReducer.educationInfo)
    const achievementsInfo = useSelector((state) => state.resumeReducer.achievementsInfo)

    let oldimageHeight = 0

    let array = []
    let contactDetailDivIDsArray = [{ 'type': 'Content', 'id': 'ContactConainter' }]
    array.push(contactDetailDivIDsArray)
    let professionalSummaryDivIDsArray = [{ 'type': 'Content', 'id': 'ProfessionalSummaryContainer' }]
    array.push(professionalSummaryDivIDsArray)
    let skillInfoDivIdsArray = [{ 'type': 'Content', 'id': 'SkillInfoContainer' }]
    array.push(skillInfoDivIdsArray)
    let eductaionInfoDivIDsArray = [{ 'type': 'Content', 'id': 'EducationInfoContainer' }]
    array.push(eductaionInfoDivIDsArray)

    let workSummaryDivIDsArray = [{ 'type': 'Heading', 'id': 'WorkInfoContainerHeading' }]
    workInfo && workInfo.map((item, index) => {
        workSummaryDivIDsArray.push({ 'type': 'Content', 'id': `WorkInfoContainerContent_${index}` })
    })
    array.push(workSummaryDivIDsArray)

    let achievementsInfoDivIDsArray = [{ 'type': 'Content', 'id': 'AchivementInfoContainer' }]
    array.push(achievementsInfoDivIDsArray)

    const downloadImage = async () => {
        let { firstName, lastName, email } = contactdetails
        let fileName = firstName + "_" + lastName + "_resume.pdf"
        oldimageHeight = 0
        let doc = new jsPDF();
        doc.margin = { horiz: 0, vert: 20 }
        oldimageHeight = 0

        const contactInfoContainerDiv = document.getElementById('ContactConainter');
        const professionalSummaryContainerDiv = document.getElementById('ProfessionalSummaryContainer');
        const skillInfoContainerDiv = document.getElementById('SkillInfoContainer');
        const educationInfoContainerDiv = document.getElementById('EducationInfoContainer');
        const achivementInfoContainerDiv = document.getElementById('AchivementInfoContainer');

        await createImages(contactInfoContainerDiv, doc, oldimageHeight)
        professionalSummaryContainerDiv && await createImages(professionalSummaryContainerDiv, doc)
        skillInfoContainerDiv && await createImages(skillInfoContainerDiv, doc)
        educationInfoContainerDiv && await createImages(educationInfoContainerDiv, doc)

        await Promise.all(
            workSummaryDivIDsArray.map(async (item) => {
                let _id = item.id
                let _workSectionItem = document.getElementById(_id);
                _workSectionItem && await createImages(_workSectionItem, doc, oldimageHeight)
            })
        )

        achivementInfoContainerDiv && await createImages(achivementInfoContainerDiv, doc, oldimageHeight)
        downlaodResumeEvent(email)
        doc.save(fileName)
    }

    const createImages = async (container, doc) => {

        let pageHeight = doc.internal.pageSize.height;
        let pageWidth = doc.internal.pageSize.width;

        let canvas = await html2canvas(container)

        let image = canvas.toDataURL('image/png');

        let ratio = canvas.width / pageWidth
        let itemHeight = canvas.height / ratio
        if (oldimageHeight + itemHeight > pageHeight - 20) {
            doc.addPage()
            oldimageHeight = 20
        }
        doc.addImage(image, 'JPEG', 0, oldimageHeight, 210, itemHeight);
        oldimageHeight = oldimageHeight + itemHeight;
    }

    return (<div>
        <PreviewParent_ downloadImage={downloadImage}>
            <ContactInfo contactdetails={contactdetails} designation={designation} divID={contactDetailDivIDsArray[0].id} />
            <ProfessionalSummary heading={TEMPLATES.PROFESSIONAL_SUMMARY} summary={professionalSummary} divID={professionalSummaryDivIDsArray[0].id} />
            <SkillsView heading={TEMPLATES.SKILLS} skills={skillInfo} divID={skillInfoDivIdsArray[0].id} />
            <EducationSummary heading={TEMPLATES.EDUCATION_SUMMARY} educationInfo={educationInfo} divID={eductaionInfoDivIDsArray[0].id} />
            <WorkSummary heading={TEMPLATES.WORK_EXPERIENCE} workInfo={workInfo} idsArray={workSummaryDivIDsArray} />
            <AchivementView heading={TEMPLATES.ACHIVEMENTS} achievementsInfo={achievementsInfo} divID={achievementsInfoDivIDsArray[0].id} />
            <div style={{ height: '20mm' }} />
        </PreviewParent_>
    </div>)
}

export default Template4