import React from "react"
import TemplateHeading from "./TemplateHeading";
import { Col, Row } from "react-bootstrap";
import './style.css';
import styled from 'styled-components';
import { secondaryColor } from "./Utils";
import HoverParent from "../components/hoverPrent";
import HorizontalLine from "../../../../component/horizontalLine";

function ProfessionalSummary({ heading, summary }) {
    return (
        <HoverParent containerId={"ProfessionalSummary"}>
            <Col >
                <TemplateHeading heading={heading} customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} />
                <Row style={{ paddingLeft: '20mm', paddingRight: '20mm' }} >
                    <Col style={{ padding: 0 }}>
                        {summary && <p className="pt-2" style={{ color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', fontSize: 14, }} class="textOverlapFix">{summary}</p>}
                    </Col>
                </Row>
            </Col>
            <HorizontalLine customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} />
        </HoverParent >
    )
}

export default ProfessionalSummary