// count.reducer.js
import RESUME_INITIALSTATE from '../../resumeTemplate'
// import RESUME_INITIALSTATE from '../../resumeSample'

import { RESET_RESUME, UPDATE_WORK_DETAIL, ADD_MORE_WORK_DETAIL, DELETE_WORK_ORDER, WORK_ORDER_COMPANY, WORK_ORDER_CITY, WORK_ORDER_JOBTITLE, WORK_ORDER_COUNTRY, WORK_ORDER_START_DATE, WORK_ORDER_END_DATE, ADD_MORE_EDUCATION, DELETE_EDUCATION, WORK_ORDER_DESCRIPTION, EDUCATION_DESCRIPTION, EDUCATION_END_DATE, EDUCATION_START_DATE, EDUCATION_CITY, EDUCATION_UNIVERSITY, SKILL_ADD_MORE, SKILL_ADD, ACHIVEMENTS_ADD_MORE, ACHIVEMENTS_ADD, CONTACT_DETAIL_WEBSITE, CONTACT_DETAIL_ADDRESS, CONTACT_DETAIL_PHONE_NUMBER, CONTACT_DETAIL_EMAIL, CONTACT_DETAIL_FIRST_NAME, CONTACT_DETAIL_LAST_NAME, PERSONAL_INFO_PROFESSIONAL_DESIGNATION, PERSONAL_INFO_PROFESSIONAL_SUMMARY, UPDATE_RESUME_WITH_STORED_DATA, SELECTED_RESUME_TEMPLATE_ID, LANGUAGE_ADD_MORE, LANGUAGE_ADD, INTEREST_ADD_MORE, INTEREST_ADD } from '../actions/ResumeActions'

const INITIAL_RESUME = RESUME_INITIALSTATE

const ResumeReducer = (state = INITIAL_RESUME, action) => {

    switch (action.type) {

        case PERSONAL_INFO_PROFESSIONAL_DESIGNATION:

            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    designation: action.designation
                }
            }

        case PERSONAL_INFO_PROFESSIONAL_SUMMARY:

            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    professionalSummary: action.professionalSummary
                }
            }

        case CONTACT_DETAIL_FIRST_NAME:

            let contactDetailFirstName = { ...state.persornalInfo.contactInfo }
            contactDetailFirstName.firstName = action.firstName
            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    contactInfo: contactDetailFirstName
                }
            }

        case CONTACT_DETAIL_LAST_NAME:

            let contactDetailLastName = { ...state.persornalInfo.contactInfo }
            contactDetailLastName.lastName = action.lastName
            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    contactInfo: contactDetailLastName
                }
            }

        case CONTACT_DETAIL_EMAIL:

            let contactDetailEmail = { ...state.persornalInfo.contactInfo }
            contactDetailEmail.email = action.email
            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    contactInfo: contactDetailEmail
                }
            }

        case CONTACT_DETAIL_PHONE_NUMBER:

            let contactDetailPhoneNumber = { ...state.persornalInfo.contactInfo }
            contactDetailPhoneNumber.phonenumber = action.phonenumber
            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    contactInfo: contactDetailPhoneNumber
                }
            }

        case CONTACT_DETAIL_WEBSITE:

            let contactDetailWebsite = { ...state.persornalInfo.contactInfo }
            contactDetailWebsite.website = action.website
            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    contactInfo: contactDetailWebsite
                }
            }

        case CONTACT_DETAIL_ADDRESS:

            let contactDetailAddress = { ...state.persornalInfo.contactInfo }
            contactDetailAddress.address = action.address
            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    contactInfo: contactDetailAddress
                }
            }

        case UPDATE_WORK_DETAIL:

            return {
                ...state, workInfo:
                    state.workInfo.map(
                        (item, i) => i === action.index
                            ? {
                                companyName: action.payload.companyName,
                                jobTitle: action.payload.jobTitle,
                                city: action.payload.city,
                                country: action.payload.country,
                                startDate: action.payload.startDate,
                                endDate: action.payload.endDate,
                            }
                            : item
                    )

            }

        // WORK ORDER DETAIL -------------------

        case ADD_MORE_WORK_DETAIL:
            return {
                ...state, workInfo:
                    [...state.workInfo, {
                        "companyName": "",
                        "jobTitle": "",
                        "city": "",
                        "country": "",
                        "startDate": "",
                        "endDate": "",
                        "description": ""
                    }]

            }

        case DELETE_WORK_ORDER:
            let newArray = state.workInfo.filter((item, index) => index != action.index)
            return {
                ...state, workInfo: newArray
            }

        case WORK_ORDER_COMPANY:
            let array_company = { ...state.workInfo[action.index] }
            array_company["companyName"] = action.companyName
            return {
                ...state, workInfo:
                    state.workInfo.map(
                        (item, i) => i === action.index
                            ? array_company
                            : item
                    )
            }

        case WORK_ORDER_CITY:
            let array_city = { ...state.workInfo[action.index] }
            array_city["city"] = action.cityName
            return {
                ...state, workInfo:
                    state.workInfo.map(
                        (item, i) => i === action.index
                            ? array_city
                            : item
                    )
            }

        case WORK_ORDER_JOBTITLE:
            let array_jobtitle = { ...state.workInfo[action.index] }
            array_jobtitle["jobTitle"] = action.jobTitle
            return {
                ...state, workInfo:
                    state.workInfo.map(
                        (item, i) => i === action.index
                            ? array_jobtitle
                            : item
                    )
            }

        case WORK_ORDER_COUNTRY:
            let array_country = { ...state.workInfo[action.index] }
            array_country["country"] = action.countryName
            return {
                ...state, workInfo:
                    state.workInfo.map(
                        (item, i) => i === action.index
                            ? array_country
                            : item
                    )
            }

        case WORK_ORDER_START_DATE:
            let array_startDate = { ...state.workInfo[action.index] }
            array_startDate["startDate"] = action.startDate
            return {
                ...state, workInfo:
                    state.workInfo.map(
                        (item, i) => i === action.index
                            ? array_startDate
                            : item
                    )
            }

        case WORK_ORDER_END_DATE:
            let array_endDate = { ...state.workInfo[action.index] }
            array_endDate["endDate"] = action.endDate
            return {
                ...state, workInfo:
                    state.workInfo.map(
                        (item, i) => i === action.index
                            ? array_endDate
                            : item
                    )
            }
        case WORK_ORDER_DESCRIPTION:
            let array_description = { ...state.workInfo[action.index] }
            array_description["description"] = action.description
            return {
                ...state, workInfo:
                    state.workInfo.map(
                        (item, i) => i === action.index
                            ? array_description
                            : item
                    )
            }


        // EDUCATION DETAIL ------------------

        case ADD_MORE_EDUCATION:
            return {
                ...state, educationInfo:
                    [...state.educationInfo, {
                        "university": "",
                        "startDate": "",
                        "endDate": "",
                        "description": "",
                        "city": ""
                    }]
            }

        case DELETE_EDUCATION:
            let filteredEductionArray = state.educationInfo.filter((item, index) => index != action.index)
            return {
                ...state, educationInfo: filteredEductionArray
            }

        case EDUCATION_UNIVERSITY:
            let education_university = { ...state.educationInfo[action.index] }
            education_university["university"] = action.university
            return {
                ...state, educationInfo:
                    state.educationInfo.map(
                        (item, i) => i === action.index
                            ? education_university
                            : item
                    )
            }
        case EDUCATION_CITY:
            let education_city = { ...state.educationInfo[action.index] }
            education_city["city"] = action.city
            return {
                ...state, educationInfo:
                    state.educationInfo.map(
                        (item, i) => i === action.index
                            ? education_city
                            : item
                    )
            }
        case EDUCATION_START_DATE:
            let education_startDate = { ...state.educationInfo[action.index] }
            education_startDate["startDate"] = action.startDate
            return {
                ...state, educationInfo:
                    state.educationInfo.map(
                        (item, i) => i === action.index
                            ? education_startDate
                            : item
                    )
            }
        case EDUCATION_END_DATE:
            let education_endDate = { ...state.educationInfo[action.index] }
            education_endDate["endDate"] = action.endDate
            return {
                ...state, educationInfo:
                    state.educationInfo.map(
                        (item, i) => i === action.index
                            ? education_endDate
                            : item
                    )
            }
        case EDUCATION_DESCRIPTION:
            let education_array_description = { ...state.educationInfo[action.index] }
            education_array_description["description"] = action.description
            return {
                ...state, educationInfo:
                    state.educationInfo.map(
                        (item, i) => i === action.index
                            ? education_array_description
                            : item
                    )
            }

        // SKILLS ----------------

        case SKILL_ADD_MORE:
            return {
                ...state, skillInfo:
                    [...state.skillInfo, ""]
            }

        case SKILL_ADD:
            return {
                ...state, skillInfo:
                    state.skillInfo.map(
                        (item, i) => i === action.index
                            ? action.skill
                            : item
                    )
            }

        // ACHIVEMENTS ----------------

        case ACHIVEMENTS_ADD_MORE:
            return {
                ...state, achievementsInfo:
                    [...state.achievementsInfo, ""]
            }

        case ACHIVEMENTS_ADD:
            return {
                ...state, achievementsInfo:
                    state.achievementsInfo.map(
                        (item, i) => i === action.index
                            ? action.achivement
                            : item
                    )
            }

        // Language ----------------

        case LANGUAGE_ADD_MORE:
            let languageEmptyObject = {
                "language": "",
                "progress": 0,
                "progressValue": ""
            }
            let mergedObject = [...state.persornalInfo.language, languageEmptyObject]
            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    language: mergedObject
                }
            }

        case LANGUAGE_ADD:

            let language_array = { ...state.persornalInfo.language[action.index] }
            language_array["language"] = action.language

            return {
                ...state, persornalInfo: {
                    ...state.persornalInfo,
                    language: state.persornalInfo.language.map((item, i) => {
                        return action.index === i ? language_array : item
                    })
                }
            }

        // INTEREST ----------------

        case INTEREST_ADD_MORE:
            return {
                ...state, interest:
                    [...state.interest, ""]
            }

        case INTEREST_ADD:
            return {
                ...state, interest:
                    state.interest.map(
                        (item, i) => i === action.index
                            ? action.interest
                            : item
                    )
            }

        case UPDATE_RESUME_WITH_STORED_DATA:
            return action.resume

        case SELECTED_RESUME_TEMPLATE_ID:
            return {
                ...state, templateId:
                    action.templateId
            }

        case RESET_RESUME: return INITIAL_RESUME
        default:
            return state
    }
}

export default ResumeReducer