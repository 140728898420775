import { configureStore } from '@reduxjs/toolkit'
// import { composeWithDevTools } from 'redux-devtools-extension'
import ProgressReducer from './reducer/ProgressReducer'
import ResumeReducer from './reducer/ResumeReducer'
import PDFCreationReducer from './reducer/PDFCreationReducer'

const reducer = {
    progressReducer: ProgressReducer,
    resumeReducer: ResumeReducer,
    pdfCreationReducer: PDFCreationReducer,
}

const store = configureStore({
    reducer
})

export default store