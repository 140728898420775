import React from "react"
import { Col, Row } from "react-bootstrap";
import './style.css';
import styles from "./style";

function HorizontalLine({ color = '#2f2f2f', customStyle, containerId }) {
    return (
        <Row id={containerId} style={{ ...styles.removeExtraSpacing }} >
            <Col style={{ display: 'table', ...customStyle, ...styles.removeExtraSpacing, ...styles.horizontalPadding_20mm }}>
                <div style={{
                    ...styles.removeExtraSpacing,
                    verticalAlign: 'middle', display: 'table-cell',
                }} >
                    <div style={{
                        backgroundColor: color, ...styles.removeExtraSpacing,
                    }}>
                        <hr class="textOverlapFix" style={{ height: 2, ...styles.removeExtraSpacing }} />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default HorizontalLine