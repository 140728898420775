import { useDispatch, useSelector } from "react-redux"
import TemplateContactInfo from "./TemplateContactInfo"
import ResumePreview from "../components/ResumePreview"
import './style.css';
import { Col, Row } from "react-bootstrap";
import ProfileView from "./ProfileView";
import ContactView from "./ContactView";
import EducationView from "./EducationView";
import SkillsView from "./SkillsView";
import WorkExperienceView from "./WorkExperienceView";
import LanguageView from "./LanguageView";
import { convertToMMInDecimals } from "../../../../util/ScreenUtil";

let minheight = 297 - convertToMMInDecimals(256) - 20
minheight = minheight + "mm"

function Template2(params) {
    const dispatch = useDispatch()


    const contactdetails = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo)
    const designation = useSelector((state) => state.resumeReducer.persornalInfo.designation)
    const professionalSummary = useSelector((state) => state.resumeReducer.persornalInfo.professionalSummary)
    const workInfo = useSelector((state) => state.resumeReducer.workInfo)
    const skillInfo = useSelector((state) => state.resumeReducer.skillInfo)
    const educationInfo = useSelector((state) => state.resumeReducer.educationInfo)
    const languageInfo = useSelector((state) => state.resumeReducer.persornalInfo.language)

    return (<div>
        <ResumePreview>
            <TemplateContactInfo contactdetails={contactdetails} designation={designation} />
            <Row className={"removeExtraSpacing"}>
                <Col xs={5} className={"contentPadding"} style={{ backgroundColor: '#e8eaed', marginLeft: '10mm', paddingLeft: '8mm', paddingRight: '8mm', minHeight: minheight }}>
                    <ProfileView professionalSummary={professionalSummary} />
                    <ContactView contactdetails={contactdetails} />
                    <LanguageView languageInfo={languageInfo} />
                </Col>
                <Col className={"contentPadding"} style={{ backgroundColor: 'white', marginRight: '15mm', paddingLeft: '8mm', paddingRight: '8mm' }}>
                    <EducationView educationInfo={educationInfo} />
                    <SkillsView skillInfo={skillInfo} />
                    <WorkExperienceView workInfo={workInfo} />
                </Col>
            </Row>
        </ResumePreview>
    </div>)
}

export default Template2