import React from "react"
import TemplateHeading from "./TemplateHeading";
import { Col, Row } from "react-bootstrap";
import { isEmpty } from "../../../../util/StringUtil";
import HoverParent from "../components/hoverPrent";
import HorizontalLine from "../../../../component/horizontalLine";

function Skills({ heading, skills }) {
    let dataSet = [[]];
    for (let i = 0, counter = 0; skills !== undefined && i < skills.length; i = i + 3, counter++) {
        let newArray = []
        if (skills[i] != undefined) {
            newArray[0] = skills[i]
        }
        if (skills[i + 1] != undefined) {
            newArray[1] = skills[i + 1]
        }
        if (skills[i + 2] != undefined) {
            newArray[2] = skills[i + 2]
        }
        dataSet[counter] = newArray
    }
    return (
        <HoverParent containerId={"Skills"}>
            <Col>
                <TemplateHeading heading={heading} customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} />
                <div className="mt-1" />
                {dataSet.map((item, i) => {
                    return <Row>
                        <SkillItem skill={isEmpty(item[0]) ? "" : item[0].toUpperCase()} />
                        <SkillItem skill={isEmpty(item[1]) ? "" : item[1].toUpperCase()} />
                        <SkillItem skill={isEmpty(item[2]) ? "" : item[2].toUpperCase()} />
                    </Row>
                })}
            </Col>
            <HorizontalLine customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} />
        </HoverParent>
    )
}

const SkillItem = ({ skill }) => {
    return <Col >
        <Row style={{ paddingLeft: '20mm', paddingRight: '20mm' }} >
            <Col md="auto mb-1" style={{ display: 'table', padding: 0 }}>
                <p class="textOverlapFix" style={{ textAlign: 'left', verticalAlign: 'middle', display: 'table-cell', fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', fontSize: 14 }}>{skill}</p>
            </Col>
        </Row>
    </Col>
}

export default Skills