import React from "react"
import { Col, Row } from "react-bootstrap";
import { primaryColor } from "../../ui/screen/templates/template1/Utils";

function HorizontalLine({ color, customStyle, containerId }) {
    return (
        <Row className="pt-2 pb-2" id={containerId}>
            <Col style={{ display: 'table', ...customStyle }}>
                <div style={{
                    verticalAlign: 'middle', display: 'table-cell',
                }} >
                    <div style={{ backgroundColor: color == undefined ? primaryColor : color }}>
                        <hr class="textOverlapFix" style={{ height: 2 }} />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default HorizontalLine