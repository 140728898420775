import { Col, InputGroup, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { isEmpty } from '../../util/StringUtil';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import styles from "./style";

export let CURRENT = "Current"

function CalenderPlaceholder({ date, placeholder, onDateClicked, isEndDate = false, setEndDate = undefined }) {

    const [switchStatus, setSwitchStatus] = useState(date === CURRENT)

    const onDatePressed = () => {
        onDateClicked && onDateClicked(true)
    }

    let value = (isEmpty(date) || date === CURRENT) ? placeholder : new Date(date).toLocaleDateString()

    const onSwitchValueChanged = (value) => {
        if (value) {
            setEndDate(CURRENT)
        }
        setSwitchStatus(!switchStatus)
    }

    return (
        <>
            <Row className="g-2">
                <Col md>
                    <InputGroup
                        className="mb-3">
                        <div onClick={switchStatus ? undefined : onDatePressed}
                            style={switchStatus ? styles.disabledSwitchStyle : styles.switchStyle}>
                            {value}
                        </div>
                    </InputGroup>
                </Col>
                {isEndDate &&
                    <Col md className="mb-3 mt-3" >
                        <Form.Check // prettier-ignore
                            id={"switch"}
                            checked={switchStatus}
                            onChange={onSwitchValueChanged}
                            type="switch"
                            label="Currently works here ? "
                        />
                    </Col>
                }
            </Row>
        </>);
}

export default CalenderPlaceholder;