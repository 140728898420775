import React from "react"
import TemplateHeading from "./TemplateHeading";
import { Col, Row } from "react-bootstrap";
import DateComponent from "../components/DateComponent";
import { secondaryColor } from "./Utils";
import './style.css';
import HoverParent from "../components/hoverPrent";

function WorkSummary({ heading, workInfo, idsArray }) {
    return (
        <HoverParent containerId={"WorkSummary"}>
            <TemplateHeading containerId={idsArray[0].id}
                heading={heading} customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} />
            {workInfo && workInfo.map((item, index) => {
                return <WorkSummaryItem
                    containerId={idsArray[index + 1].id}
                    index={index}
                    title={item.companyName}
                    subtitle={item.jobTitle}
                    description={item.description}
                    startDate={item.startDate}
                    endDate={item.endDate}
                />
            })}
        </HoverParent >
    )
}

const WorkSummaryItem = ({ title, subtitle, description, startDate, endDate, index, containerId }) => {
    return (
        <div id={containerId} >
            <Row style={{ paddingLeft: '20mm', paddingRight: '20mm' }}>
                <Col style={{ padding: 0 }} className="pt-2"  >

                    <Row className="mt-1">
                        <Col style={{ display: 'table' }}>
                            <p style={{ verticalAlign: 'middle', display: 'table-cell', color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '700', fontStyle: 'normal', fontSize: 14 }}>{title.toUpperCase()}
                            </p>
                        </Col>
                        <Col md="auto" style={{ display: 'table' }}>
                            <DateComponent startDate={startDate} endDate={endDate} customStyle={{ color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', fontSize: 14 }} />
                        </Col>
                    </Row>
                    <Col style={{ display: 'table' }}>
                        <p style={{ verticalAlign: 'middle', display: 'table-cell', color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '700', fontStyle: 'normal', fontSize: 14 }}>{subtitle.toUpperCase()}</p>
                    </Col>
                    <p className="mt-1" dangerouslySetInnerHTML={{ __html: description }} style={{ color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', fontSize: 14 }} />
                </Col>
            </Row>
        </div>)
}



export default WorkSummary