import { useContext, useEffect } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../../data/store/actions/ProgressActions';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../../../component/appHeader';
import { addInterest, addMoreInterest, addMoreSkill, addSkill } from '../../../data/store/actions/ResumeActions';
import { ACHIVEMENT_ROUTE } from '../../../AppRouter';
import { DimensionContext } from '../../../util/DimensionContext';
import { BUTTONS, HEADERS, INPUT_FIELDS } from '../../../util/Constants';

function Skills() {

    const dispatch = useDispatch()
    const width = useContext(DimensionContext);

    const skillInfo = useSelector((state) => state.resumeReducer.skillInfo)
    const interest = useSelector((state) => state.resumeReducer.interest)

    useEffect(() => {
        dispatch(updateProgress(83))
    }, [])

    return (
        <Container fluid className={width < 800 ? "p2" : "p-5"}>
            <SkillsView skillInfo={skillInfo} />
            <InteresetView interest={interest} />
        </Container>
    );
}

const SkillsView = ({ skillInfo }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const navigateToAchivementsScreen = () => {
        navigate(ACHIVEMENT_ROUTE)
    }

    const goBack = () => {
        navigate(-1)
    }

    const setSkill = (skill, index) => {
        dispatch(addSkill(skill, index))
    }

    const addMore = () => {
        dispatch(addMoreSkill())
    }
    return (
        <>
            <AppHeader title={HEADERS.SKILLS} onBack={goBack} onNext={navigateToAchivementsScreen} />
            {skillInfo.map((item, index) => {
                return <InputGroup className="px-5 pt-3" style={{ marginBottom: index === skillInfo.length - 1 ? 10 : 0 }}>
                    <Form.Control
                        required
                        placeholder={INPUT_FIELDS.SKILL}
                        aria-label={INPUT_FIELDS.SKILL}
                        defaultValue={item}
                        onChange={(event) => { setSkill(event.target.value, index) }}
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
            })}
            <Button className='ms-5 mb-5' variant="primary" onClick={addMore}>
                {BUTTONS.ADD_MORE}
            </Button>
        </>
    )
}

const InteresetView = ({ interest }) => {
    const dispatch = useDispatch()

    const addInterest_ = (interest, index) => {
        dispatch(addInterest(interest, index))
    }

    const addMoreInterest_ = () => {
        dispatch(addMoreInterest())
    }

    return (<>
        <AppHeader title={"Add your Interest"} isNextEnabled={false} isBackEnabled={false} isSaveEnabled={false} isPreviewEnabled={false} />
        {
            interest.map((item, index) => {
                return <InputGroup className="mb-3 px-5 ">
                    <Form.Control
                        required
                        placeholder="Interest"
                        aria-label="Interest"
                        defaultValue={item}
                        onChange={(event) => { addInterest_(event.target.value, index) }}
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
            })
        }
        <Button className='ms-5' variant="primary" onClick={addMoreInterest_}>
            {'Add more'}
        </Button>
    </>)
}

export default Skills;