import { useNavigate } from "react-router-dom";
import AppHeader from "../../../../../component/appHeader"
import './style.css';
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { downlaodResumeEvent } from "../../../../../util/Analytics";
function ResumePreview(props) {

    const navigate = useNavigate();
    const contactdetails = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo)

    let { firstName, lastName, email } = contactdetails
    let fileName = firstName + "_" + lastName + "_resume.pdf"
    let doc = new jsPDF();

    const downloadImage = async () => {
        doc = new jsPDF();
        downlaodResumeEvent(email)
        const contactInfoContainer = document.getElementById('content-id');
        contactInfoContainer && await createImages(contactInfoContainer)
        downlaodResumeEvent()
        doc.save(fileName);
    }

    const createImages = async (container) => {
        let pageWidth = doc.internal.pageSize.width;
        let canvas = await html2canvas(container)
        let image = canvas.toDataURL('image/png');
        let ratio = canvas.width / pageWidth
        let itemHeight = canvas.height / ratio
        // let itemHeight = canvas.height / 7.54
        doc.addImage(image, 'JPEG', 0, 0, 210, itemHeight);
    }

    return (
        <div style={{ marginBottom: '20mm' }}>
            <AppHeader title={"Resume Preview"}
                isDownloadEnabled={true}
                isPreviewEnabled={false}
                onBack={() => {
                    navigate(-1)
                }}
                isNextEnabled={false}
                onClickDownload={() => { downloadImage() }} />
            <div>
                <div class="page">
                    <div class="subpage">
                        <div id="content-id">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default ResumePreview