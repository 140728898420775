const styles = {
    textStyle: {
        fontFamily: 'Noto Sans',
    },
    secondaryColor: {
        color: "white"
    },
    primaryColor: {
        color: "#373737"
    },
    tertiaryColor: {
        color: "#777777"
    },
    primaryBGColor: {
        backgroundColor: '#323737'
    },
    tertiaryBGColor: {
        color: "#777777"
    },
    horizontalMargin: {
        marginLeft: '15mm',
        marginRight: '15mm'
    },
    headingVerticalMargin: {
        marginTop: '5mm',
        marginBottom: '5mm'
    },
    removeExtraSpacing: {
        margin: 0,
        padding: 0
    }
}

export default styles