import React from "react"
import { Col, Row } from "react-bootstrap";
import { primaryColor } from "./Utils";

function TemplateHeading({ heading, customStyle, containerId }) {
    heading = heading.toUpperCase()

    return (
        <div id={containerId} >
            <Row style={{ ...customStyle }}>
                <Col md="auto" style={{ display: 'table', padding: 0 }}>
                    <p className="fs-5" class="textOverlapFix" style={{ verticalAlign: 'middle', display: 'table-cell', color: primaryColor, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', letterSpacing: '.1em', fontSize: 17 }}>{heading}</p>
                </Col>
            </Row >
        </div>
    )
}

export default TemplateHeading