import { isEmpty } from "../../util/StringUtil";
import { verifyUserToken } from "../../data/network/usertoken";

export const useVerifyUserToken = () => {
    async function verifyToken(email, token) {
        if (!isEmpty(email)) {
            let response = await verifyUserToken({
                "email": email,
                "token": token,
            })
            return response
        }
    }
    return { verifyToken }
}

