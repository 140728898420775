import React from "react"
import { Col, Row } from "react-bootstrap";
import './style.css';
import styles from "./style";
import Heading from "./Heading";
import sanitizeHtml from 'sanitize-html';

const ReactDOMServer = require('react-dom/server');
const HtmlToReactParser = require('html-to-react').Parser;

function AchivementView({ heading, achievementsInfo, divID }) {

    if (achievementsInfo == undefined || achievementsInfo.length === 0) {
        return false
    }

    return (
        <Col id={divID}>
            <Heading heading={heading} />
            {achievementsInfo.map((item, i) => {
                return (<Row style={{ ...styles.removeExtraSpacing }}>
                    <AchievementsItem
                        description={item}
                    />
                </Row>)
            })}
        </Col >
    )
}

const AchievementsItem = ({ description, containerId = undefined }) => {
    let html = sanitizeHtml(description);
    const htmlToReactParser = new HtmlToReactParser();
    const reactElement = htmlToReactParser.parse(html);
    return (
        <Col style={{ ...styles.removeExtraSpacing, ...styles.horizontalPadding_20mm }}>
            <Row className="mt-3" >
                <Col>
                    <div style={{ ...styles.textStyle, fontStyle: 'normal', fontWeight: '400', fontSize: 14, ...styles.removeExtraSpacing, ...styles.textStyle }}>{reactElement}</div>
                </Col>
            </Row>
        </Col>
    )
}



export default AchivementView