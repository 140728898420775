import React from "react"
import { Col, Row } from "react-bootstrap";
import DateComponent from "../components/DateComponent";
import './style.css';
import HoverParent from "../components/hoverPrent";
import styles from "./style";
import Heading from "./Heading";
import VerticalLine, { VERTICAL_LINE_CONTENT_SPACING } from "./VerticalLine";
import sanitizeHtml from 'sanitize-html';

const ReactDOMServer = require('react-dom/server');
const HtmlToReactParser = require('html-to-react').Parser;

function AchivementView({ heading, achievementsInfo, divID }) {

    if (achievementsInfo == undefined || achievementsInfo.length === 0) {
        return false
    }

    let dataSet = [[]];
    for (let i = 0, counter = 0; achievementsInfo !== undefined && i < achievementsInfo.length; i = i + 2, counter++) {
        let newArray = []
        if (achievementsInfo[i] != undefined) {
            newArray[0] = achievementsInfo[i]
        }
        if (achievementsInfo[i + 1] != undefined) {
            newArray[1] = achievementsInfo[i + 1]
        }
        dataSet[counter] = newArray
    }

    return (
        <Col
            id={divID}
            style={{ ...styles.horizontalPadding }}>
            <Heading heading={heading} customStyle={{ ...styles.headingVerticalMargin }} />
            {dataSet.map((item, i) => {
                return (<Row style={{ ...styles.removeExtraSpacing }}>
                    <AchievementsItem
                        description={item[0]}
                    />
                    <AchievementsItem
                        description={item[1]}
                    />
                </Row>)
            })}
        </Col >
    )
}

const AchievementsItem = ({ description, containerId = undefined }) => {
    let html = sanitizeHtml(description);
    const htmlToReactParser = new HtmlToReactParser();
    const reactElement = htmlToReactParser.parse(html);
    return (
        <Col style={{ ...styles.removeExtraSpacing }}>
            <Row className="mt-3" >
                <Col xs={1} style={{ maxWidth: VERTICAL_LINE_CONTENT_SPACING, ...styles.removeExtraSpacing }}>
                    <VerticalLine />
                </Col>
                <Col>
                    <div style={{ ...styles.textStyle, fontStyle: 'normal', fontSize: 14, ...styles.removeExtraSpacing, ...styles.secondaryColor }}>{reactElement}</div>
                </Col>
            </Row>
        </Col>
    )
}



export default AchivementView