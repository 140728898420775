import { Routes, Route, BrowserRouter, HashRouter } from 'react-router-dom';
import React from "react";
import Home from './ui/screen/home';
import UserDetail from './ui/screen/userDetail';
import { ProgressBar } from 'react-bootstrap';
import WorkDetail from './ui/screen/workdetail';
import { useSelector } from 'react-redux'
import EducationDetail from './ui/screen/educationdetail';
import Skills from './ui/screen/skills';
import Achievements from './ui/screen/achivements';
import Template0 from './ui/screen/templates/template0';
import ProfessionalDetail from './ui/screen/professionalDetail';
import Template1 from './ui/screen/templates/template1';
import AppAlertView from './component/alert';
import Template2 from './ui/screen/templates/template2';
import Template3 from './ui/screen/templates/template3';
import Template4 from './ui/screen/templates/template4';
import Template5 from './ui/screen/templates/template5';

const AppRouter = () => {

    const progress = useSelector((state) => state.progressReducer.progress)
    const templateId = useSelector((state) => state.resumeReducer.templateId)

    return (
        <HashRouter>
            <ProgressBar variant="success" now={progress} style={{ borderRadius: 0 }} />
            <AppAlertView message={"Best viewing/accessing experience is with Laptops/Tablets !"} variant={'success'} />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path={USER_DETAIL_ROUTE} element={<UserDetail />} />
                <Route path={PROFESSIONAL_DETAIL} element={<ProfessionalDetail />} />
                <Route path={WORK_DETAIL_ROUTE} element={<WorkDetail />} />
                <Route path={EDUCATION_DETAIL_ROUTE} element={<EducationDetail />} />
                <Route path={SKILLS_ROUTE} element={<Skills />} />
                <Route path={ACHIVEMENT_ROUTE} element={<Achievements />} />
                <Route path={SELECTED_TEMPLATE} element={<Templates selectedTemplate={templateId} />} />
            </Routes>
        </HashRouter>
    );
}

const Templates = ({ selectedTemplate }) => {
    switch (selectedTemplate) {
        case 1:
            return <Template1 />
        case 2:
            return <Template2 />
        case 3:
            return <Template3 />
        case 4:
            return <Template4 />
        case 5:
            return <Template5 />
        default:
            return <Template2 />
    }
}

export const USER_DETAIL_ROUTE = "/userDetail"
export const PROFESSIONAL_DETAIL = "/professionalDetail"
export const WORK_DETAIL_ROUTE = "/workDetail"
export const EDUCATION_DETAIL_ROUTE = "/educationDetail"
export const SKILLS_ROUTE = "/skills"
export const ACHIVEMENT_ROUTE = "/achivements"
export const SELECTED_TEMPLATE = "/selected_template"

export default AppRouter;