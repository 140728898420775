import React from "react"
import { Col, Row } from "react-bootstrap";
import { isEmpty } from "../../../../util/StringUtil";
import Heading from "./Heading";
import styles from "./style";

function LanguageView({ heading, languages }) {
    let dataSet = [[]];
    for (let i = 0, counter = 0; languages !== undefined && i < languages.length; i = i + 3, counter++) {
        let newArray = []
        if (languages[i] != undefined) {
            newArray[0] = languages[i].language
        }
        if (languages[i + 1] != undefined) {
            newArray[1] = languages[i + 1].language
        }
        if (languages[i + 2] != undefined) {
            newArray[2] = languages[i + 2].language
        }
        dataSet[counter] = newArray
    }
    return (
        <Col style={{ ...styles.horizontalMargin }}>
            <Heading icon={"LiaLanguageSolid"} heading={heading} customStyle={{ ...styles.headingVerticalMargin, ...styles.primaryColor }} />
            {dataSet.map((item, i) => {
                return <Row style={{ ...styles.removeExtraSpacing, marginTop: 5 }}>
                    <LanguageItem language={isEmpty(item[0]) ? "" : item[0].toUpperCase()} />
                    <LanguageItem language={isEmpty(item[1]) ? "" : item[1].toUpperCase()} />
                    <LanguageItem language={isEmpty(item[2]) ? "" : item[2].toUpperCase()} />
                </Row>
            })}
        </Col>
    )
}

const LanguageItem = ({ language }) => {
    return <Col >
        <Row >
            <Col md="auto" style={{ display: 'table', ...styles.removeExtraSpacing }}>
                {!isEmpty(language) && <p style={{ textAlign: 'left', fontSize: 5, verticalAlign: 'middle', display: 'table-cell' }}>{`\u2B24`}</p>}
            </Col>
            <Col style={{ display: 'table', ...styles.removeExtraSpacing, marginLeft: 8 }}>
                <p class="textOverlapFix" style={{ textAlign: 'left', verticalAlign: 'middle', display: 'table-cell', ...styles.textStyle, ...styles.primaryColor, fontWeight: '400', fontStyle: 'normal', fontSize: 14 }}>{language}</p>
            </Col>
        </Row >
    </Col >
}

export default LanguageView