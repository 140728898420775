import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Editor from 'react-simple-wysiwyg';
import { isEmpty } from '../../util/StringUtil';

function AppEditor(props) {

    const [html, setHtml] = useState(props.description);

    useEffect(() => {
        let description = props.description
        setHtml(description)
    }, [])

    const savePressed = () => {
        setHtml();
        props.savePressed && props.savePressed(html)
    }

    function onChange(e) {
        setHtml(e.target.value);
    }

    function onClose() {
        setHtml();
        props.onHide?.()
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Editor
                    placeholder='Description ...'
                    value={html} onChange={onChange} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="primary">Close</Button>
                <Button onClick={savePressed} variant="primary">Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AppEditor