import { Card, Col, Image } from "react-bootstrap"
import "./style.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./style";
import { COMMON } from '../../../util/Constants';

let templateWidth = 300
let templateHeight = templateWidth * 1.41
let infoHeight = 50
let infoBGcolor = 'lightgray'

function TemplateView({ template, navigateToUserDetailscreen }) {

    const { height, width } = useWindowDimensions();

    const onTemplateClick = () => {
        navigateToUserDetailscreen && navigateToUserDetailscreen(template)
    }

    let templateInfo = COMMON.FOR + " " + (template.user === COMMON.EXPERIENCE ? COMMON.EXPERIENCE : COMMON.STUDENT)

    return (
        <Col className="mt-5">
            <div
                style={{ height: templateHeight, position: 'relative' }}>
                <div style={{
                    height: templateHeight, width: templateWidth,
                    ...styles.carShadow,
                    display: 'table',
                    margin: '0 auto'
                }}>
                    <Image
                        src={template.templateImg} height={templateHeight} width={templateWidth} onClick={() => onTemplateClick(template)} />
                    <div style={{ position: 'absolute', bottom: 0, width: templateWidth + 20, borderTop: '50px', borderTopStyle: 'solid', borderTopColor: infoBGcolor, borderLeft: '20px', borderLeftStyle: 'solid', borderLeftColor: 'transparent', marginLeft: - 20 }}>
                    </div>
                    <div style={{ position: 'absolute', bottom: 0, width: templateWidth, height: infoHeight, }}>
                        <h3 style={{ textAlign: 'left', marginTop: 8, marginLeft: 8 }}>{templateInfo}</h3>
                    </div>
                </div>
            </div >
        </Col>)
}

export default TemplateView