import React from "react"
import './style.css';
import styles from "./style";

export const VERTICAL_LINE_CONTENT_SPACING = 30
const VERTICAL_LINE_WIDTH = 4
export let VERTICAL_LINE_MARGIN = (VERTICAL_LINE_CONTENT_SPACING - VERTICAL_LINE_WIDTH) / 2


function VerticalLine({ color = "gray" }) {
    return (
        <div style={{
            height: '100%', width: '100%',
            paddingRight: VERTICAL_LINE_MARGIN,
            paddingLeft: VERTICAL_LINE_MARGIN,
        }}>
            <div style={{
                backgroundColor: color,
                height: '100%',
                width: VERTICAL_LINE_WIDTH,
                borderRadius: 40
            }} />
        </div>
    )
}

export default VerticalLine