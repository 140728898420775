import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { USER_DETAIL_ROUTE } from '../../../AppRouter';
import { useDispatch } from 'react-redux';
import { updateProgress } from '../../../data/store/actions/ProgressActions';
import { getTemplate } from '../../../data/network/template';
import AppHeader from '../../../component/appHeader';
import { selectedTemplate, updateResumeWithStoredData } from '../../../data/store/actions/ResumeActions';
import { useLoadUserResume } from '../../hooks/useLoadUserResume';
import ResumeDownloaderUploader from '../../../component/resumeDownloaderUploader';
import { isEmpty } from '../../../util/StringUtil';
import TemplateView from './TemplateView';
import RESUME_INITIALSTATE from '../../../data/resumeSample1';
import { HEADERS } from '../../../util/Constants';
import { isDevEnv } from '../../../util/Analytics';

function Home() {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [templates, setTemplates] = useState([[]])
    let { loadResume } = useLoadUserResume()
    const [emailPopupStatus, setEmailPopUpStatus] = useState(false)

    useEffect(() => {
        dispatch(updateProgress(0))
    }, [])

    const navigateToUserDetailscreen = (item) => {
        navigate(USER_DETAIL_ROUTE)
        dispatch(selectedTemplate(item.templateID))
    }

    useEffect(() => {
        async function fetchData() {
            let data = await getTemplate()
            let templatesArray = data.response
            let dataSet = [[]];
            for (let i = 0, counter = 0; i < templatesArray.length; i = i + 2, counter++) {
                let newArray = []
                newArray[0] = isEmpty(templatesArray[i]) ? [] : templatesArray[i]
                newArray[1] = isEmpty(templatesArray[i + 1]) ? [] : templatesArray[i + 1]
                dataSet[counter] = newArray
            }
            setTemplates(dataSet)
        }
        fetchData()
    }, [])

    const onResumeDownload = async (email) => {
        let _resume = await loadResume(email)
        if (_resume == undefined) return
        if (_resume.response == undefined) return
        dispatch(updateResumeWithStoredData(_resume.response.resume))
    }

    const onLoad = async () => {
        if (isDevEnv()) {
            console.log("RESUME_INITIALSTATE : ", JSON.stringify(RESUME_INITIALSTATE))
            dispatch(updateResumeWithStoredData(RESUME_INITIALSTATE))
        } else {
            setEmailPopUpStatus(true)
        }
    }
    const environment = process.env.REACT_APP_ENVIORNMENT;
    return (
        <Container fluid className='pt-5 pb-5'>
            <AppHeader onLoad={onLoad} isSaveEnabled={false} title={HEADERS.SELECT_TEMPLATE} isBackEnabled={false} isNextEnabled={false} isLoadButtonEnabled={true} isPreviewEnabled={false} />
            {templates.map((item, i) => {
                return <Row>
                    {templates[i][0] && <Col style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                        {templates[i][0].templateImg && <TemplateView template={templates[i][0]} navigateToUserDetailscreen={navigateToUserDetailscreen} />}
                    </Col>}
                    {templates[i][1] && <Col style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                        {templates[i][1].templateImg && <TemplateView template={templates[i][1]} navigateToUserDetailscreen={navigateToUserDetailscreen} />}
                    </Col>}
                </Row>
            })}

            <ResumeDownloaderUploader
                isDownloader={true}
                show={emailPopupStatus}
                savePressed={(value) => {
                    setEmailPopUpStatus(false)
                    onResumeDownload(value)
                }}
                onHide={() => { setEmailPopUpStatus(false) }}
            />
        </Container>
    );
}

export default Home;
