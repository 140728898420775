import React from "react"
import './style.css';
import AppHeader from "../../../../../component/appHeader";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { downlaodResumeEvent } from "../../../../../util/Analytics";

let allowedDivIdsToDownload = ['TemplateContactInfo', 'ProfessionalSummary', 'Skills', 'EducationSummary', 'WorkSummary']

function PreviewParent(props) {

    const navigate = useNavigate();
    const contactdetails = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo)
    const pdfCreationReducer = useSelector((state) => state.pdfCreationReducer.data)
    let workInfoIdDetails = pdfCreationReducer.filter((item) => { return item[0].id === 'workInfoContainerId' })
    let educationInfoIdDetails = pdfCreationReducer.filter((item) => { return item[0].id === 'eductaionInfoContainerId' })

    let { firstName, lastName, email } = contactdetails
    let fileName = firstName + "_" + lastName + "_resume.pdf"
    let oldimageHeight = 0
    let doc = new jsPDF();
    doc.margin = { horiz: 0, vert: 20 }

    const goBack = () => {
        navigate(-1)
    }

    const downloadImage = async () => {
        doc = new jsPDF();

        const contactInfoContainer = document.getElementById('TemplateContactInfo');
        const professionalSummaryContainer = document.getElementById('ProfessionalSummary');
        const skillsContainer = document.getElementById('Skills');

        contactInfoContainer && await createImages(contactInfoContainer)
        professionalSummaryContainer && await createImages(professionalSummaryContainer)
        skillsContainer && await createImages(skillsContainer)

        let educationsummaryfilteredItem = educationInfoIdDetails[0]

        await Promise.all(
            educationsummaryfilteredItem.map(async (item) => {
                let _id = item.id
                let _educationSectionItem = document.getElementById(_id);
                _educationSectionItem && await createImages(_educationSectionItem)
            })
        )

        let worksummaryfilteredItem = workInfoIdDetails[0]

        await Promise.all(
            worksummaryfilteredItem.map(async (item) => {
                let _id = item.id
                let _workSectionItem = document.getElementById(_id);
                _workSectionItem && await createImages(_workSectionItem)
            })
        )
        downlaodResumeEvent(email)

        doc.save(fileName);
    }

    const createImages = async (container) => {

        let pageHeight = doc.internal.pageSize.height;
        let pageWidth = doc.internal.pageSize.width;

        let canvas = await html2canvas(container)

        let image = canvas.toDataURL('image/png');
        let ratio = canvas.width / pageWidth
        let itemHeight = canvas.height / ratio
        // let itemHeight = canvas.height / 7.54
        if (oldimageHeight + itemHeight > pageHeight) {
            doc.addPage()
            oldimageHeight = 20
        }
        doc.addImage(image, 'JPEG', 0, oldimageHeight, 210, itemHeight);
        oldimageHeight = oldimageHeight + itemHeight;
    }

    return (
        <div style={{ marginBottom: '20mm' }}>
            <AppHeader title={"Resume Preview"} isDownloadEnabled={true} isPreviewEnabled={false} onBack={goBack} isNextEnabled={false} onClickDownload={() => downloadImage()} />
            <div>
                <div class="page">
                    <div class="subpage">
                        <div id="content-id" >
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default PreviewParent