// count.reducer.js
import { CLEAR_DIV_IDS_DATA, SET_DIV_IDS_DATA } from '../actions/PDFCreationActions'

// [[{ 'type': 'Heading', 'id': '' }, { 'type': 'content', 'id': '' }]]

const INITIAL_STATE = {
    data: [[]]
}

const PDFCreationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DIV_IDS_DATA: {
            return { data: action.payload }
        }
        case CLEAR_DIV_IDS_DATA: return INITIAL_STATE
        default: return state
    }
}

export default PDFCreationReducer