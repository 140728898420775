const RESUME_INITIALSTATE = {
  "templateId": "",
  "persornalInfo": {
    "language": [
      {
        "language": "",
        "progress": 0,
        "progressValue": ""
      }
    ],
    "contactInfo": {
      "firstName": "",
      "lastName": "",
      "email": "",
      "phonenumber": "",
      "website": "",
      "address": ""
    },
    "professionalSummary": "",
    "designation": ""
  },
  "workInfo": [
    {
      "companyName": "",
      "jobTitle": "",
      "city": "",
      "country": "",
      "startDate": "",
      "endDate": "",
      "description": ""
    },
  ],
  "educationInfo": [
    {
      "university": "",
      "startDate": "",
      "endDate": "",
      "description": "",
      "city": ""
    }
  ],
  "skillInfo": [
    "", "", ""
  ],
  "achievementsInfo": [
    "", ""
  ],
  "interest": [
    "",
    ""
  ]
}

export default RESUME_INITIALSTATE