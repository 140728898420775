// count.reducer.js
import { RESET_PROGRESS, UPDATE_PROGRESS } from '../actions/ProgressActions'

const INITIAL_PROGRESS = {
    progress: 0
}

const ProgressReducer = (state = INITIAL_PROGRESS, action) => {
    switch (action.type) {
        case UPDATE_PROGRESS: return { progress: action.payload }
        case RESET_PROGRESS: return { progress: 0 }
        default: return state
    }
}

export default ProgressReducer