import { Button, Col, Container, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { WORK_DETAIL_ROUTE } from '../../../AppRouter';
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../../data/store/actions/ProgressActions';
import { useContext, useEffect, useState } from 'react';
import { updatePersonalInfoDesignation, updatePersonalInfoSummary } from '../../../data/store/actions/ResumeActions';
import AppHeader from '../../../component/appHeader';
import { DimensionContext } from '../../../util/DimensionContext';
import { HEADERS, INPUT_FIELDS } from '../../../util/Constants';

function ProfessionalDetail() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const width = useContext(DimensionContext);

    const [isValid, setValidationStatus] = useState(false)

    const designation = useSelector((state) =>
        state.resumeReducer.persornalInfo.designation
    )

    const professionalSummary = useSelector((state) =>
        state.resumeReducer.persornalInfo.professionalSummary
    )

    useEffect(() => {
        dispatch(updateProgress(32))
    }, [])

    const navigateToWorkDetailScreen = () => {
        navigate(WORK_DETAIL_ROUTE)
    }

    const setDesignation = (value) => {
        dispatch(updatePersonalInfoDesignation(value))
    }

    const setProfessionalSummary = (value) => {
        dispatch(updatePersonalInfoSummary(value))
    }

    const onSubmit = (event) => {
        const form = event.currentTarget;
        setValidationStatus(!form.checkValidity());
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            navigateToWorkDetailScreen()
        }
    };

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Container fluid className={width < 800 ? "p2" : "p-5"}>
            <Form noValidate validated={isValid} onSubmit={onSubmit}>
                <AppHeader title={HEADERS.PROFESSIONAL_SUMMARY} onBack={goBack} />

                <InputGroup className="mb-3 px-5 pt-3">
                    <Form.Control
                        required
                        placeholder={INPUT_FIELDS.DESIGNATION}
                        aria-label={INPUT_FIELDS.DESIGNATION}
                        aria-describedby="basic-addon1"
                        defaultValue={designation}
                        onChange={(event) => { setDesignation(event.target.value) }}
                    />
                </InputGroup>

                <InputGroup className="mb-3 px-5">
                    <Form.Control
                        required
                        placeholder={INPUT_FIELDS.SUMMARY}
                        aria-label={INPUT_FIELDS.SUMMARY}
                        aria-describedby="basic-addon1"
                        defaultValue={professionalSummary}
                        onChange={(event) => { setProfessionalSummary(event.target.value) }}
                    />
                </InputGroup>

            </Form>

        </Container>
    );
}

export default ProfessionalDetail;