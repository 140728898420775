import React from "react"
import TemplateHeading from "./TemplateHeading";
import { Col, Row } from "react-bootstrap";
import DateComponent from "../components/DateComponent";
import { secondaryColor } from "./Utils";
import HoverParent from "../components/hoverPrent";
import HorizontalLine from "../../../../component/horizontalLine";

function EducationSummary({ heading, educationData, idsArray }) {

    return (
        <HoverParent containerId={"EducationSummary"}>
            <TemplateHeading containerId={idsArray[0].id} heading={heading} customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} />
            {educationData && educationData.map((item, index) => {
                return <EducationItem
                    containerId={idsArray[index + 1].id}
                    title={item.university}
                    description={item.description}
                    city={item.city}
                    startDate={item.startDate}
                    endDate={item.endDate}
                />
            })}
            <HorizontalLine containerId={idsArray[educationData.length + 1].id} customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} />
        </HoverParent>
    )
}

const EducationItem = ({ title, description, startDate, endDate, city, containerId }) => {
    return (
        <div id={containerId} >
            <Row style={{ paddingLeft: '20mm', paddingRight: '20mm' }}>
                <Col style={{ padding: 0 }} className="pt-2"  >
                    <Row className="mb-1">
                        <Col style={{ display: 'table' }}>
                            <p class="textOverlapFix" style={{ verticalAlign: 'middle', display: 'table-cell', color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '700', fontStyle: 'normal', fontSize: 14 }}>{title.toUpperCase()}</p>
                        </Col>
                        <Col md="auto" style={{ display: 'table' }}>
                            <DateComponent shouldShowYearOnly startDate={startDate} endDate={endDate} customStyle={{ color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', fontSize: 14 }} />
                        </Col>
                    </Row>
                    <p class="textOverlapFix" style={{ verticalAlign: 'middle', display: 'table-cell', color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', fontSize: 14 }}>{city.toUpperCase()}</p>
                    <p className="mt-1" dangerouslySetInnerHTML={{ __html: description }} style={{ color: secondaryColor, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', fontSize: 14 }} />
                </Col>
            </Row>
        </div>)
}



export default EducationSummary