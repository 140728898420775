import React from "react"
import './style.css';
import { convertToMM } from "../../../../util/ScreenUtil";
import { Col, Row } from "react-bootstrap";
import TextWithIcon from "../../../../component/textWithIcon";
import styles from "./style";
let contentHeight = convertToMM(256)
let contentHorizontalPadgding = `25mm`

function TemplateContactInfo({ contactdetails, designation }) {

    let { firstName, lastName, email, phonenumber, address, website } = contactdetails
    let name = firstName + " " + lastName
    name = name && name.toUpperCase()
    designation = designation && designation.toUpperCase()

    return (<div>
        <div style={{ height: contentHeight, ...styles.primaryBGColor }}>
            <div style={{ height: contentHeight, ...styles.primaryBGColor, alignItems: 'center', display: 'flex' }}>
                <div style={{ width: '100%', paddingLeft: contentHorizontalPadgding, paddingRight: contentHorizontalPadgding }}>
                    <p className="removeExtraSpacing" style={{ ...styles.textStyle, ...styles.secondaryColor, fontWeight: 'bolder', fontSize: 56, marginBottom: -10 }}>{designation}</p>
                    <p className="removeExtraSpacing" style={{ ...styles.textStyle, ...styles.secondaryColor, fontWeight: 'normal', fontSize: 24, }}>{name}</p>
                    <Row xs="auto" style={{ marginTop: '10mm' }}>
                        <Col>
                            <TextWithIcon justifyContent={"center"} icon='BiLogoGmail' text={email} customTextStyle={{ fontSize: 14, marginLeft: 5, ...styles.textStyle, ...styles.secondaryColor, fontWeight: 'normal', }} iconSize={14} iconColor="white" />
                        </Col>
                        <Col>
                            <TextWithIcon justifyContent={"center"} icon='BsFillTelephoneFill' text={phonenumber} customTextStyle={{ fontSize: 14, marginLeft: 5, ...styles.textStyle, ...styles.secondaryColor, fontWeight: 'normal', }} iconSize={14} iconColor="white" />
                        </Col>
                        <Col>
                            <TextWithIcon justifyContent={"center"} icon='BsFillHouseFill' text={address} customTextStyle={{ fontSize: 14, marginLeft: 5, ...styles.textStyle, ...styles.secondaryColor, fontWeight: 'normal', }} iconSize={14} iconColor="white" />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </div >)
}

export default TemplateContactInfo