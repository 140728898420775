import { Col, Row } from "react-bootstrap"
import TextWithIcon from "../../../../component/textWithIcon"
import styles from './style'

function Heading({ heading = "heading", customStyle, icon = 'BiLogoGmail' }) {
    heading = heading.toUpperCase()
    return (
        <Row style={{ ...customStyle, paddingTop: '5mm' }}>
            <Col style={{ display: 'table' }}>
                <div style={{
                    verticalAlign: 'middle', display: 'table-cell',
                }} >
                    <div style={{ ...styles.tertiaryBGColor }}>
                        <hr class="textOverlapFix" style={{ height: '1px' }} />
                    </div>
                </div>
            </Col>
            <Col md="auto" style={{ display: 'table', }}>
                <div style={{
                    verticalAlign: 'middle', display: 'table-cell'
                }} >
                    <TextWithIcon icon={icon} text={heading} customTextStyle={{ fontSize: 14, marginLeft: 10, fontFamily: 'Noto Sans', fontWeight: 'bold', }} iconSize={16} iconColor="#777777" />
                </div>
            </Col>
            <Col style={{ display: 'table' }}>
                <div style={{
                    verticalAlign: 'middle', display: 'table-cell',
                }} >
                    <div style={{ ...styles.tertiaryBGColor }}>
                        <hr class="textOverlapFix" style={{ height: '1px' }} />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default Heading