import React from "react"
import './style.css';
import { Col, Row } from "react-bootstrap";
import styles from "./style";
import { capitalizeFirstLetter } from "../../../../util/StringUtil";
import VerticalLine from "./VerticalLine";

let height = '7mm'

function ContactInfo({ contactdetails, designation, divID }) {

    let { firstName, lastName, email, phonenumber, address, website } = contactdetails
    let name = firstName + " " + lastName
    name = name && name.toUpperCase()
    designation = designation && designation.toUpperCase()
    address = capitalizeFirstLetter(address)
    return (<Col id={divID}>

        <Row style={{ ...styles.horizontalPadding_20mm }}>
            <Col className='pb-2' style={{ display: 'table', paddingTop: '20mm' }}>
                <p class="textOverlapFix" className="fs-4" style={{ textAlign: 'center', verticalAlign: 'middle', display: 'table-cell' }}>
                    {name.split(" ").map((word, index) => {
                        return <span style={{ lineHeight: 0, fontSize: 40, color: styles.primaryColor, letterSpacing: '.2em', fontFamily: styles.textStyle, fontWeight: '700' }}>{`${word} `}</span>;
                    })}
                </p>
            </Col>
            {designation &&
                <PTag value={designation} customStyle={{ fontFamily: styles.textStyle, fontWeight: '400', letterSpacing: '.2em', }} />
            }
        </Row>

        <Row style={{ height: height + '4mm', ...styles.removeExtraSpacing, ...styles.horizontalPadding }}>
            <Row className="justify-content-md-center"
                style={{ backgroundColor: '#ebded4', paddingTop: '2mm', paddingBottom: '2mm', ...styles.removeExtraSpacing, verticalAlign: 'middle' }}
            >
                <Col md="auto" style={{ alignItems: 'center', display: 'table' }} >
                    <p class="textOverlapFix" style={{ color: styles.secondaryColor, align: 'right', ...styles.removeExtraSpacing, ...styles.contactInfoSectionPadding, fontFamily: styles.textStyle, verticalAlign: 'middle', display: 'table-cell' }}>{address}</p>
                </Col>
                <Col md="auto" style={{ ...styles.removeExtraSpacing, alignContent: 'center' }}>
                    <VerticalLine height={height} />
                </Col>
                <Col md="auto" style={{ alignContent: 'center', display: 'table' }} >
                    <p class="textOverlapFix" style={{ color: styles.secondaryColor, align: 'center', ...styles.removeExtraSpacing, ...styles.contactInfoSectionPadding, fontFamily: styles.textStyle, verticalAlign: 'middle', display: 'table-cell' }}>{phonenumber}</p>
                </Col>
                <Col md="auto"
                // style={{ ...styles.removeExtraSpacing, alignContent: 'center' }}
                >
                    <VerticalLine height={height} />
                </Col>
                <Col md="auto" style={{ alignContent: 'center', display: 'table' }} >
                    <p class="textOverlapFix" style={{ color: styles.secondaryColor, align: 'left', ...styles.removeExtraSpacing, ...styles.contactInfoSectionPadding, fontFamily: styles.textStyle, verticalAlign: 'middle', display: 'table-cell' }}>{email}</p>
                </Col>
            </Row>
        </Row>
        {/* <HorizontalLine customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} /> */}
    </Col>)
}

const PTag = ({ value, customStyle = undefined }) => {
    return <p class="textOverlapFix" style={{ color: styles.secondaryColor, whiteSpace: 'nowrap', textAlign: 'center', verticalAlign: 'middle', display: 'table-cell', ...customStyle }}>{value}</p>
}

export default ContactInfo