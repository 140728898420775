import Heading from "./Heading"
import './style.css';
import styles from "./style";
import { TEMPLATES } from "../../../../util/Constants";

function LanguageView({ languageInfo }) {
    return (<>
        <Heading heading={TEMPLATES.LANGUAGES} customStyle={{ paddingTop: '15mm' }} />
        {languageInfo && languageInfo.map((item) => {
            return <LanguageItem
                language={item.language}
            />
        })}
    </>)
}

const LanguageItem = ({ language }) => {
    return (<>
        <p style={{ ...styles.textStyle, marginBottom: 5, padding: 0, fontWeight: 'normal', fontSize: 16 }}>{language}</p>
    </>)
}

export default LanguageView