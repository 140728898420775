const styles = {
    textStyle: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal'
    },
    textStyleSecondary: {
        fontFamily: 'Noto Sans',
        fontStyle: 'normal'
    },
    primaryColor: {
        color: "#111111"
    },
    secondaryColor: {
        color: "#646464"
    },
    tertiaryColor: {
        color: "#8e8e8e"
    },
    primaryBGColor: {
        backgroundColor: '#d9d9d9'
    },
    horizontalPadding: {
        paddingLeft: '15mm',
        paddingRight: '15mm',
    },
    horizontalPadding_20mm: {
        paddingLeft: '20mm',
        paddingRight: '20mm',
    },
    headingVerticalMargin: {
        paddingTop: '12mm',
        // paddingBottom: '2mm'
    },
    headingVerticalMargin_5mm: {
        paddingTop: '5mm',
        // paddingBottom: '2mm'
    },
    removeExtraSpacing: {
        margin: 0,
        padding: 0
    },
    contactInfoSectionPadding: {
        paddingLeft: '5mm',
        paddingRight: '5mm',
    }
}

export default styles