import React from "react"
import './style.css';
import styles from "./style";


let marginVerticalHeight = 5
function VerticalLine({ color = "#423e3b", height }) {

    return (
        <div style={{
            backgroundColor: color,
            height: height,
            flex: 1,
            width: 1,
            borderRadius: 2,
            marginTop: marginVerticalHeight,
            marginBottom: marginVerticalHeight
        }} />
    )
}

export default VerticalLine