import ApiConstant from "../coreutils/ApiConstant"
import { REQUEST_TYPE, axiosRequest } from "../networkRequest"

export async function sendTokenToUser(data) {
    let requestData = {
        [ApiConstant.URL]: "token/sendTokenToUser",
        [ApiConstant.METHOD]: REQUEST_TYPE.POST,
        [ApiConstant.PARAMS]: data,
    }
    let response = await axiosRequest(requestData)
    return response
}

export async function verifyUserToken(data) {
    let requestData = {
        [ApiConstant.URL]: "token/verifyUserToken",
        [ApiConstant.METHOD]: REQUEST_TYPE.POST,
        [ApiConstant.PARAMS]: data,
    }
    let response = await axiosRequest(requestData)
    return response
}