const styles = {
    textStyle: {
        fontFamily: 'Noto Sans',
    },
    primaryColor: {
        color: "#111111"
    },
    secondaryColor: {
        color: "#646464"
    },
    tertiaryColor: {
        color: "#8e8e8e"
    },
    primaryBGColor: {
        backgroundColor: '#d9d9d9'
    },
    horizontalPadding: {
        paddingLeft: '15mm',
        paddingRight: '15mm',
    },
    headingVerticalMargin: {
        paddingTop: '5mm',
        // paddingBottom: '2mm'
    },
    removeExtraSpacing: {
        margin: 0,
        padding: 0
    }
}

export default styles