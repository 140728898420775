import React from "react"
import { Col, Row } from "react-bootstrap";
import './style.css';
import Heading from "./Heading";
import styles from "./style";
let verticalMargin = '5mm'
let horizontalMargin = '15mm'

function ProfessionalSummary({ heading, summary }) {
    return (
        <Col style={{ ...styles.horizontalMargin }}>
            <Heading heading={heading} customStyle={{ ...styles.headingVerticalMargin }} icon="GrUserWorker" />
            {summary && <p style={{ ...styles.removeExtraSpacing, fontSize: 14, fontWeight: 'normal', ...styles.textStyle, ...styles.primaryColor }} class="textOverlapFix">{summary} </p>}
        </Col>
    )
}

export default ProfessionalSummary