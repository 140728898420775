import DynamicIcon from "../dynamicIcon";
import './style.css'

function TextWithIcon({ text, icon, customContainerStyle, customTextStyle, iconSize = 15, iconColor = 'black', justifyContent = 'left', iconStyle }) {
    return (
        <div style={{
            display: 'grid',
            justifyContent: justifyContent,
            alignItems: 'center'
        }}>
            <div style={{ ...customContainerStyle, padding: 0, margin: 0, display: 'flex', alignItems: 'center' }}>
                <DynamicIcon nameIcon={icon} propsIcon={{ size: iconSize, color: iconColor, margin: 0, padding: 0, ...iconStyle }} />
                <p className="singleLine" style={{
                    // display: 'inline-block', 
                    marginLeft: 13, ...customTextStyle
                }}>{text}</p>
            </div>
        </div>)
}

export default TextWithIcon