import { useEffect, useState } from 'react';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { BsXLg, BsCalendarEvent } from 'react-icons/bs';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import EditorPlaceholder from '../../../component/editorPlaceholder';
import CalenderPlaceholder from '../../../component/calenderPlaceholder';
import DateComponent from '../../../component/dateComponent';
import { isEmpty } from '../../../util/StringUtil';
import { BUTTONS, INPUT_FIELDS } from '../../../util/Constants';

function EducationDetails(props) {

    const [shouldOpensStartDatePicker, setStartDatePickerStatus] = useState(false)
    const [shouldOpensEndDatePicker, setEndDatePickerStatus] = useState(false)

    useEffect(() => {
        let { city, university, startDate, endDate } = props
        setCity_(city)
        setUniversity_(university)
        setStartDate_(startDate)
        setEndDate_(endDate)
    }, [])

    const setCity_ = (value) => {
        props.setCity && props.setCity(value, props.index)
    }
    const setUniversity_ = (value) => {
        props.setUniversity && props.setUniversity(value, props.index)
    }
    const setStartDate_ = (value) => {
        props.setStartDate && props.setStartDate(value, props.index)
    }
    const setEndDate_ = (value) => {
        props.setEndDate && props.setEndDate(value, props.index)
    }

    const openStartDatePicker = () => {
        setStartDatePickerStatus(!shouldOpensStartDatePicker)
    }

    const openEndDatePicker = () => {
        setEndDatePickerStatus(!shouldOpensEndDatePicker)
    }

    const onDeletePress = () => {
        props.deleteItem && props.deleteItem(props.index)
    }

    const onEditorFocused = (value) => {
        props.setDescriptionModelStatusCallback && props.setDescriptionModelStatusCallback(value, props.index)
    }

    return (
        <>
            <>
                {props.index != 0 && <Row className="px-5 mt-3">
                    <Col>
                    </Col>
                    <Col md="auto" >
                        <BsXLg onClick={onDeletePress} style={{ width: 18, height: 18 }} className="mb-3 mx-5 me-1" />
                    </Col>
                </Row>}

                <Row className="g-2 px-5 pt-3">
                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                required
                                placeholder={INPUT_FIELDS.UNIVERSITY_COLLEGE_SCHOOL}
                                aria-label={INPUT_FIELDS.UNIVERSITY_COLLEGE_SCHOOL}
                                aria-describedby="basic-addon1"
                                defaultValue={props.university}
                                onChange={(event) => { setUniversity_(event.target.value) }}
                            />
                        </InputGroup>
                    </Col>

                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                required
                                placeholder={INPUT_FIELDS.DEGREE}
                                aria-label={INPUT_FIELDS.CITY}
                                defaultValue={props.city}
                                onChange={(event) => { setCity_(event.target.value) }}
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="g-2 px-5">
                    <Col md>
                        <CalenderPlaceholder
                            onDateClicked={() => {
                                openStartDatePicker()
                            }}
                            placeholder={INPUT_FIELDS.START_DATE}
                            date={props.startDate}
                        />
                    </Col>
                    <Col md>
                        <CalenderPlaceholder
                            onDateClicked={() => {
                                openEndDatePicker()
                            }}
                            placeholder={INPUT_FIELDS.END_DATE}
                            date={props.endDate}
                        />
                    </Col>
                </Row>
                <EditorPlaceholder
                    placeholder={INPUT_FIELDS.DESCRIPTION}
                    description={props.description}
                    onEditorFocused={onEditorFocused} />
            </>
            <DateComponent
                show={shouldOpensStartDatePicker}
                maxDate={isEmpty(props.endDate) ? new Date() : props.endDate}
                onHide={openStartDatePicker}
                onDateClicked={(value) => {
                    setStartDate_(value)
                    openStartDatePicker()
                }} />

            <DateComponent
                show={shouldOpensEndDatePicker}
                minDate={props.startDate}
                onHide={openEndDatePicker}
                onDateClicked={(value) => {
                    setEndDate_(value)
                    openEndDatePicker()
                }} />
        </>);
}

export default EducationDetails;