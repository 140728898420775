import { Button, Col, Container, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { PROFESSIONAL_DETAIL, WORK_DETAIL_ROUTE } from '../../../AppRouter';
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../../data/store/actions/ProgressActions';
import { useContext, useEffect, useState } from 'react';
import { updateContactDetailAddress, updateContactDetailEmail, updateContactDetailFirstName, updateContactDetailLastName, updateContactDetailPhoneNumber, updateContactDetailWebsite } from '../../../data/store/actions/ResumeActions';
import AppHeader from '../../../component/appHeader';
import { DimensionContext } from '../../../util/DimensionContext';
import { haveStartedCreatingResumeEvent } from '../../../util/Analytics';
import { HEADERS, INPUT_FIELDS } from '../../../util/Constants';

function UserDetail() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [isValid, setValidationStatus] = useState(false)

    const contactdetails = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo)

    const userEmail = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo.email)

    const width = useContext(DimensionContext);

    useEffect(() => {
        dispatch(updateProgress(16))
    }, [])

    const navigateToWorkDetailScreen = () => {
        navigate(PROFESSIONAL_DETAIL)
    }

    const setFirstName = (value) => {
        dispatch(updateContactDetailFirstName(value))
    }

    const setLastName = (value) => {
        dispatch(updateContactDetailLastName(value))
    }

    const setEmail = (value) => {
        dispatch(updateContactDetailEmail(value))
    }

    const setPhoneNumber = (value) => {
        dispatch(updateContactDetailPhoneNumber(value))
    }

    const setWebsite = (value) => {
        dispatch(updateContactDetailWebsite(value))
    }

    const setAddress = (value) => {
        dispatch(updateContactDetailAddress(value))
    }

    const onSubmit = (event) => {
        haveStartedCreatingResumeEvent(userEmail)
        const form = event.currentTarget;
        setValidationStatus(!form.checkValidity());
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            navigateToWorkDetailScreen()
        }
    };

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Container fluid className={width < 800 ? "p2" : "p-5"}>
            <Form noValidate validated={isValid} onSubmit={onSubmit}>
                <AppHeader title={HEADERS.CONTACT_DETAILS} onBack={goBack} />
                <Row className="g-2 px-5 pt-3">
                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                required
                                placeholder={INPUT_FIELDS.FIRSTNAME}
                                aria-label={INPUT_FIELDS.FIRSTNAME}
                                defaultValue={contactdetails.firstName}
                                onChange={(event) => { setFirstName(event.target.value) }}
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Col>
                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                required
                                placeholder={INPUT_FIELDS.LASTNAME}
                                aria-label={INPUT_FIELDS.LASTNAME}
                                defaultValue={contactdetails.lastName}
                                onChange={(event) => { setLastName(event.target.value) }}
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="g-2 px-5">
                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                required
                                placeholder={INPUT_FIELDS.EMAIL}
                                aria-label={INPUT_FIELDS.EMAIL}
                                defaultValue={contactdetails.email}
                                onChange={(event) => { setEmail(event.target.value) }}
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Col>
                    <Col md>
                        <InputGroup className="mb-3">
                            <Form.Control
                                required
                                placeholder={INPUT_FIELDS.PHONE_NUMBER}
                                aria-label={INPUT_FIELDS.PHONE_NUMBER}
                                aria-describedby="basic-addon1"
                                defaultValue={contactdetails.phonenumber}
                                onChange={(event) => { setPhoneNumber(event.target.value) }}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <InputGroup className="mb-3 px-5">
                    <Form.Control
                        placeholder={INPUT_FIELDS.WEBSITE}
                        aria-label={INPUT_FIELDS.WEBSITE}
                        aria-describedby="basic-addon1"
                        defaultValue={contactdetails.website}
                        onChange={(event) => { setWebsite(event.target.value) }}
                    />
                </InputGroup>


                <InputGroup className="mb-3 px-5">
                    <Form.Control
                        required
                        placeholder={INPUT_FIELDS.ADDRESS}
                        aria-label={INPUT_FIELDS.ADDRESS}
                        aria-describedby="basic-addon1"
                        defaultValue={contactdetails.address}
                        onChange={(event) => { setAddress(event.target.value) }}
                    />
                </InputGroup>
            </Form>
        </Container>
    );
}

export default UserDetail;