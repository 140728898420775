

export default {
    //CONFIGURATION
    URL: "url",
    METHOD: "method",
    HEADERS: "headers",
    PARAMS: "params",
    DATA: "data",
    TIMEOUT: "timeout",

    //HEADER PARAMS
    ACCEPT: "Accept",
    BEARER: "Bearer",
    CONTENT_TYPE: "Content-Type",
    AUTHORIZATION: "Authorization",
    APPLICATION_JSON: "application/json",
    TOKEN: "token",
}