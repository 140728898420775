import ApiConstant from "../coreutils/ApiConstant"
import { REQUEST_TYPE, axiosRequest } from "../networkRequest"

export async function getUserResume(data) {
    let requestData = {
        [ApiConstant.URL]: "userresume",
        [ApiConstant.METHOD]: REQUEST_TYPE.GET,
        [ApiConstant.PARAMS]: data,
    }
    let response = await axiosRequest(requestData)
    return response
}

export async function postUserResume(data) {
    let requestData = {
        [ApiConstant.URL]: "userresume",
        [ApiConstant.METHOD]: REQUEST_TYPE.POST,
        [ApiConstant.DATA]: data,
    }

    let response = await axiosRequest(requestData)
    return response
}