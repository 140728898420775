import './style.css';
import styles from "./style";
import { Col, Row } from 'react-bootstrap';
import Heading from './Heading';
import DateComponent from '../components/DateComponent';

function EducationSummary({ heading, educationInfo, idsArray }) {
    if (educationInfo == undefined || educationInfo.length === 0) {
        return false
    }
    heading = heading.toUpperCase()
    return (
        <Col >
            <Heading heading={heading} id={idsArray[0].id} />
            {educationInfo && educationInfo.map((item, index) => {
                return <EducationSummaryItem
                    title={item.university}
                    description={item.description}
                    city={item.city}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    containerId={idsArray[index + 1].id}
                />
            })}
        </Col>)
}

const EducationSummaryItem = ({ title, city, description, startDate, endDate, containerId }) => {
    return (
        <div id={containerId} >
            <Row style={{ ...styles.removeExtraSpacing, ...styles.horizontalPadding_20mm }}>
                <Col style={{ padding: 0 }} className="pt-2"  >

                    <Row className="mt-1">
                        <Col style={{ display: 'table' }}>
                            <p style={{ verticalAlign: 'middle', display: 'table-cell', fontWeight: '700', fontSize: 14, ...styles.textStyleSecondary }}>{title.toUpperCase()}
                            </p>
                        </Col>
                        <Col md="auto" style={{ display: 'table' }}>
                            <DateComponent startDate={startDate} endDate={endDate} customStyle={{ fontWeight: '400', fontSize: 14, ...styles.textStyle }} />
                        </Col>
                    </Row>
                    <Col style={{ display: 'table' }}>
                        <p style={{ verticalAlign: 'middle', display: 'table-cell', fontWeight: '700', fontSize: 14, ...styles.textStyleSecondary }}>{city.toUpperCase()}</p>
                    </Col>
                    <p className="mt-1" dangerouslySetInnerHTML={{ __html: description }} style={{ fontWeight: '400', fontSize: 14, ...styles.textStyle }} />
                </Col>
            </Row>
        </div>)
}

export default EducationSummary
