import React from "react"
import { Col, Row } from "react-bootstrap";
import DateComponent from "../components/DateComponent";
import './style.css';
import HoverParent from "../components/hoverPrent";
import styles from "./style";
import Heading from "./Heading";
import VerticalLine, { VERTICAL_LINE_CONTENT_SPACING } from "./VerticalLine";
import sanitizeHtml from 'sanitize-html';

const ReactDOMServer = require('react-dom/server');
const HtmlToReactParser = require('html-to-react').Parser;

function WorkSummary({ heading, workInfo, idsArray }) {
    return (
        <Col >
            <Heading id={idsArray[0].id} heading={heading} customStyle={{ ...styles.headingVerticalMargin, ...styles.horizontalPadding }} />
            {workInfo && workInfo.map((item, index) => {
                return <WorkSummaryItem
                    id={idsArray[index + 1].id}
                    index={index}
                    title={item.companyName}
                    subtitle={item.jobTitle}
                    description={item.description}
                    startDate={item.startDate}
                    endDate={item.endDate}
                />
            })}
        </Col >
    )
}

const WorkSummaryItem = ({ title, subtitle, description, startDate, endDate, id = undefined }) => {
    let html = sanitizeHtml(description);
    const htmlToReactParser = new HtmlToReactParser();
    const reactElement = htmlToReactParser.parse(html);
    return (
        <Col
            id={id}
            className="pt-3"
            style={{ ...styles.removeExtraSpacing }} >
            <Row style={{ ...styles.horizontalPadding }}>
                <Col xs={1} style={{ maxWidth: VERTICAL_LINE_CONTENT_SPACING, ...styles.removeExtraSpacing }}>
                    <VerticalLine />
                </Col>
                <Col>
                    <p style={{ ...styles.removeExtraSpacing, ...styles.textStyle, fontWeight: '700', fontStyle: 'normal', fontSize: 14 }}>{title.toUpperCase()}
                    </p>
                    <DateComponent startDate={startDate} endDate={endDate} customStyle={{ ...styles.removeExtraSpacing, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', fontSize: 14, ...styles.secondaryColor }} />
                    <p style={{ ...styles.removeExtraSpacing, verticalAlign: 'middle', fontStyle: 'normal', fontSize: 14, ...styles.secondaryColor }}>{subtitle.toUpperCase()}</p>
                    <div style={{ ...styles.textStyle, fontStyle: 'normal', fontSize: 14, ...styles.removeExtraSpacing, ...styles.secondaryColor }}>{reactElement}</div>
                </Col>
            </Row>
        </Col>
    )
}



export default WorkSummary