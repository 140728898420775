import ErrorCode from './ErrorCode';
import ErrorMsg from './ErrorMsg';
import ResponseKeys from './ResponseKeys';

export function networkError() {
    let networkError = {
        [ResponseKeys.STATUS_CODE]: ErrorCode.NO_NETWORK,
        [ResponseKeys.ERROR_MESSAGE]: ErrorMsg.NO_NETWORK,
    };
    return createResponseObject(networkError)
}

export function apiError(code, message) {
    let apiError = {
        [ResponseKeys.STATUS_CODE]: code,
        [ResponseKeys.ERROR_MESSAGE]: message,
    };
    return createResponseObject(apiError)
}

export function successResp(response) {
    let successResponse = {
        [ResponseKeys.STATUS_CODE]: response.status,
        [ResponseKeys.RESPONSE]: response.data,
    };
    let resp = createResponseObject(successResponse)
    return resp
}

function createResponseObject(object) {
    let response = {

        [ResponseKeys.STATUS_CODE]: object[ResponseKeys.STATUS_CODE],

        [ResponseKeys.ERROR_MESSAGE]: object[ResponseKeys.ERROR_MESSAGE] = undefined
            ? undefined
            : object[ResponseKeys.ERROR_MESSAGE],

        [ResponseKeys.RESPONSE]: object[ResponseKeys.RESPONSE] = undefined
            ? undefined
            : object[ResponseKeys.RESPONSE],
    };

    return response
}


