export const SET_DIV_IDS_DATA = 'SET_DIV_IDS_DATA'
export const CLEAR_DIV_IDS_DATA = 'CLEAR_DIV_IDS_DATA'

export const setData = (value) => ({
    type: SET_DIV_IDS_DATA,
    payload: value
})
export const clearData = () => ({
    type: CLEAR_DIV_IDS_DATA,
})
