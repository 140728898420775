import React from "react"
import { Col, Row } from "react-bootstrap";
import { isEmpty } from "../../../../util/StringUtil";
import Heading from "./Heading";
import styles from "./style";

function SkillsView({ heading, skills }) {
    let dataSet = [[]];
    for (let i = 0, counter = 0; skills !== undefined && i < skills.length; i = i + 4, counter++) {
        let newArray = []
        if (skills[i] != undefined) {
            newArray[0] = skills[i]
        }
        if (skills[i + 1] != undefined) {
            newArray[1] = skills[i + 1]
        }
        if (skills[i + 2] != undefined) {
            newArray[2] = skills[i + 2]
        }
        if (skills[i + 3] != undefined) {
            newArray[3] = skills[i + 3]
        }
        dataSet[counter] = newArray
    }
    return (
        <Col style={{ ...styles.horizontalMargin }}>
            <Heading icon="GiSkills" heading={heading} customStyle={{ ...styles.headingVerticalMargin, ...styles.primaryColor }} />
            {dataSet.map((item, i) => {
                return <Row style={{ ...styles.removeExtraSpacing, marginTop: 5 }}>
                    <SkillItem skill={isEmpty(item[0]) ? "" : item[0].toUpperCase()} />
                    <SkillItem skill={isEmpty(item[1]) ? "" : item[1].toUpperCase()} />
                    <SkillItem skill={isEmpty(item[2]) ? "" : item[2].toUpperCase()} />
                    <SkillItem skill={isEmpty(item[3]) ? "" : item[3].toUpperCase()} />
                </Row>
            })}
        </Col>
    )
}

const SkillItem = ({ skill }) => {
    return <Col >
        <Row >
            <Col md="auto" style={{ display: 'table', ...styles.removeExtraSpacing }}>
                {!isEmpty(skill) && <p style={{ textAlign: 'left', fontSize: 5, verticalAlign: 'middle', display: 'table-cell' }}>{`\u2B24`}</p>}
            </Col>
            <Col style={{ display: 'table', ...styles.removeExtraSpacing, marginLeft: 8 }}>
                <p class="textOverlapFix" style={{ textAlign: 'left', verticalAlign: 'middle', display: 'table-cell', ...styles.textStyle, ...styles.primaryColor, fontWeight: '400', fontStyle: 'normal', fontSize: 14 }}>{skill}</p>
            </Col>
        </Row >
    </Col >
}

export default SkillsView