import { isEmpty } from "../../util/StringUtil";
import { sendTokenToUser } from "../../data/network/usertoken";

export const useSendUserToken = () => {
    function sendUserToken(email) {
        if (!isEmpty(email)) {
            sendTokenToUser({
                "email": email,
            })
        }
    }
    return { sendUserToken }
}

