import React from "react"
import './style.css';
import AppHeader from "../../../../../component/appHeader";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";

function PreviewParent_(props) {

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }

    const downloadImage = async () => {
        props?.downloadImage()
    }

    return (
        <div style={{ marginBottom: '20mm' }}>
            <AppHeader title={"Resume Preview"} isDownloadEnabled={true} isPreviewEnabled={false} onBack={goBack} isNextEnabled={false} onClickDownload={() => downloadImage()} />
            <div>
                <div class="page">
                    <div class="subpage">
                        <div id="content-id" >
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default PreviewParent_