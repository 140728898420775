import ApiConstant from "../coreutils/ApiConstant"
import { REQUEST_TYPE, axiosRequest } from "../networkRequest"

export async function getTemplate() {
    let data = {
        [ApiConstant.URL]: "resumetemplate",
        [ApiConstant.METHOD]: REQUEST_TYPE.GET,
    }

    let response = await axiosRequest(data)
    return response
}