import Heading from "./Heading"
import './style.css';
import styles from "./style";
import { TEMPLATES } from "../../../../util/Constants";

function SkillsView({ skillInfo }) {
    return (<>
        <Heading heading={TEMPLATES.SKILLS} customStyle={{ paddingTop: '15mm' }} />
        {skillInfo && skillInfo.map((item) => {
            return <SkillItem
                skill={item}
            />
        })}
    </>)
}

const SkillItem = ({ skill }) => {
    return (<>
        <p style={{ ...styles.textStyle, marginBottom: 5, padding: 0, fontWeight: 'normal', fontSize: 16 }}>{skill}</p>
    </>)
}

export default SkillsView