import React, { useRef } from "react"
import './style.css';
import { useDispatch, useSelector } from "react-redux";
import PreviewParent from "../components/previewParent";
import TemplateContactInfo from "./TemplateContactInfo";
import HorizontalLine from "../../../../component/horizontalLine";
import ProfessionalSummary from "./ProfessionalSummary";
import Skills from "./Skills";
import { isEmpty } from "../../../../util/StringUtil";
import EducationSummary from "./EducationSummary";
import WorkSummary from "./WorkSummary";
import { setData } from "../../../../data/store/actions/PDFCreationActions";
import { TEMPLATES } from "../../../../util/Constants";


function Template1() {
    const dispatch = useDispatch()


    const contactdetails = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo)
    const designation = useSelector((state) => state.resumeReducer.persornalInfo.designation)
    const professionalSummary = useSelector((state) => state.resumeReducer.persornalInfo.professionalSummary)
    const workInfo = useSelector((state) => state.resumeReducer.workInfo)
    const skillInfo = useSelector((state) => state.resumeReducer.skillInfo)
    const educationInfo = useSelector((state) => state.resumeReducer.educationInfo)

    // Data Creation for PDF-CONTAINER_ID
    let array = []
    let contactDetailsArray = [{ 'type': 'Heading', 'id': 'contactDetailsContainerId' }, { 'type': 'content', 'id': 'contactDetailsContainerContent' }]
    array.push(contactDetailsArray)
    let professionalSummaryArray = [{ 'type': 'Heading', 'id': 'professionalSummaryContainerId' }, { 'type': 'content', 'id': 'professionalSummaryContainerContent' }]
    array.push(professionalSummaryArray)
    let skillInfoArray = [{ 'type': 'Heading', 'id': 'skillInfoContainerId' }, { 'type': 'content', 'id': 'skillInfoContainerContent' }]
    array.push(skillInfoArray)

    let eductaionInfoArray = [{ 'type': 'Heading', 'id': 'eductaionInfoContainerId' }]
    educationInfo && educationInfo.length >= 1 && educationInfo.map((item, index) => {
        eductaionInfoArray.push({ 'type': 'content', 'id': `eductaionInfoContainerContent_${index}` })
    })
    eductaionInfoArray.push({ 'type': 'Divider', 'id': `eductaionInfoContainerContent_divider` })
    array.push(eductaionInfoArray)

    let workSummaryArray = [{ 'type': 'Heading', 'id': 'workInfoContainerId' }]
    workInfo && workInfo.length >= 1 && workInfo.map((item, index) => {
        workSummaryArray.push({ 'type': 'content', 'id': `workInfoContainerContent_${index}` })
    })
    array.push(workSummaryArray)

    dispatch(setData(array))

    return (<PreviewParent>
        <>
            <TemplateContactInfo contactdetails={contactdetails} designation={designation} idsArray={contactDetailsArray} />
            <ProfessionalSummary heading={TEMPLATES.PROFESSIONAL_SUMMARY} summary={professionalSummary} idsArray={professionalSummaryArray} />
            <Skills heading={TEMPLATES.SKILLS} skills={skillInfo} idsArray={skillInfoArray} />
            <EducationSummary heading={TEMPLATES.EDUCATION} educationData={educationInfo} idsArray={eductaionInfoArray} />
            <WorkSummary heading={TEMPLATES.WORK_SUMMARY} workInfo={workInfo} idsArray={workSummaryArray} />
        </>
    </PreviewParent>)
}

export default Template1