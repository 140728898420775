export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const RESET_PROGRESS = 'RESET_PROGRESS'

export const updateProgress = (value) => ({
    type: UPDATE_PROGRESS,
    payload: value
})
export const resetProgress = () => ({
    type: RESET_PROGRESS,
})
