import { useSelector } from "react-redux";
import isResumeEmpty from "../../util/ResumeUtil";
import { postUserResume } from "../../data/network/userresume";
import { isEmpty } from "../../util/StringUtil";

export const useSaveUserResume = () => {
    let resume = useSelector((state) => state.resumeReducer)
    let email = useSelector((state) => state.resumeReducer.persornalInfo.contactInfo.email)

    async function saveResume() {

        if (isResumeEmpty(resume) || isEmpty(email)) {
            alert("Nothing to Save")
            return
        }

        if (!isResumeEmpty(resume)) {
            await postUserResume({
                "email": email,
                "resume": resume
            })
        }
    }
    return { saveResume }
}

