import './App.css';
import AppRouter from './AppRouter';
import React, { useEffect } from "react";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import WebFont from 'webfontloader';
import { DimensionContext } from './util/DimensionContext';
import useWindowDimensions from './ui/hooks/useWindowDimensions';

function App() {

  const { height, width } = useWindowDimensions();


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto']
      }
    });
  }, []);

  return (
    <DimensionContext.Provider value={width}>
      <AppRouter />
    </DimensionContext.Provider>
  );
}

export default App;
