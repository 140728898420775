import './style.css';
import styles from "./style";
import { Col } from 'react-bootstrap';
import HorizontalLine from './HorizontalLine';

function Heading({ heading, customStyle, id = undefined }) {
    heading = heading.toUpperCase()
    return (
        <Col id={id} style={{ ...styles.removeExtraSpacing, ...styles.headingVerticalMargin, paddingBottom: '3mm' }}>
            <p style={{ letterSpacing: '.1em', color: '#1e1e1e', ...styles.textStyle, fontWeight: 'bolder', fontSize: 17, textAlign: 'left', ...styles.removeExtraSpacing, ...styles.horizontalPadding_20mm, paddingBottom: '2mm' }}>{heading}</p>
            <HorizontalLine />
        </Col>)
}

export default Heading
