import React from "react"
import { Col, Row } from "react-bootstrap";
import DateComponent from "../components/DateComponent";
import './style.css';
import HoverParent from "../components/hoverPrent";
import styles from "./style";
import Heading from "./Heading";
import VerticalLine, { VERTICAL_LINE_CONTENT_SPACING } from "./VerticalLine";
import sanitizeHtml from 'sanitize-html';

const ReactDOMServer = require('react-dom/server');
const HtmlToReactParser = require('html-to-react').Parser;

function EducationSummary({ heading, educationInfo, divID = undefined }) {

    let dataSet = [[]];
    for (let i = 0, counter = 0; educationInfo !== undefined && i < educationInfo.length; i = i + 2, counter++) {
        let newArray = []
        if (educationInfo[i] != undefined) {
            newArray[0] = educationInfo[i]
        }
        if (educationInfo[i + 1] != undefined) {
            newArray[1] = educationInfo[i + 1]
        }
        dataSet[counter] = newArray
    }

    return (
        <Col
            id={divID}
            style={{ ...styles.horizontalPadding }}>
            <Heading heading={heading} customStyle={{ ...styles.headingVerticalMargin }} />
            {dataSet.map((item, i) => {
                return (<Row style={{ ...styles.removeExtraSpacing }}>
                    <EducationSummaryItem
                        title={item[0]?.university}
                        description={item[0]?.description}
                        city={item[0]?.city}
                        startDate={item[0]?.startDate}
                        endDate={item[0]?.endDate}
                    />
                    <EducationSummaryItem
                        title={item[1]?.university}
                        description={item[1]?.description}
                        city={item[1]?.city}
                        startDate={item[1]?.startDate}
                        endDate={item[1]?.endDate}
                    />
                </Row>)
            })}
        </Col >
    )
}

const EducationSummaryItem = ({ title, city, description, startDate, endDate, index, containerId = undefined }) => {
    return (
        <Col style={{ ...styles.removeExtraSpacing }}>
            <Row className="mt-3" >
                <Col xs={1} style={{ maxWidth: VERTICAL_LINE_CONTENT_SPACING, ...styles.removeExtraSpacing }}>
                    <VerticalLine />
                </Col>
                <Col>
                    <p style={{ ...styles.removeExtraSpacing, ...styles.textStyle, fontWeight: '700', fontStyle: 'normal', fontSize: 14, }}>{title?.toUpperCase()}</p>
                    <DateComponent startDate={startDate} endDate={endDate} customStyle={{ ...styles.removeExtraSpacing, ...styles.textStyle, fontWeight: '400', fontStyle: 'normal', fontSize: 14, ...styles.secondaryColor }} />
                    <p style={{ ...styles.removeExtraSpacing, ...styles.textStyle, verticalAlign: 'middle', fontStyle: 'normal', fontSize: 14, ...styles.secondaryColor }}>{city?.toUpperCase()}</p>
                </Col>
            </Row>
        </Col>
    )
}



export default EducationSummary