import React from "react"
import './style.css';
import { Col, Row } from "react-bootstrap";
import { primaryColor, secondaryColor } from "./Utils";
import HoverParent from "../components/hoverPrent";
import HorizontalLine from "../../../../component/horizontalLine";

function TemplateContactInfo({ contactdetails, designation }) {

    let { firstName, lastName, email, phonenumber, address, website } = contactdetails
    let name = firstName + " " + lastName
    name = name && name.toUpperCase()
    designation = designation && designation.toUpperCase()
    return (<HoverParent containerId={"TemplateContactInfo"}>
        <Row style={{ paddingLeft: '20mm', paddingRight: '20mm' }}>
            <Col className='pb-2' style={{ display: 'table', paddingTop: '20mm' }}>
                <p class="textOverlapFix" className="fs-4" style={{ textAlign: 'center', verticalAlign: 'middle', display: 'table-cell' }}>
                    {name.split(" ").map((word, index) => {
                        return <span style={{ fontSize: 40, color: index == 0 ? primaryColor : secondaryColor, letterSpacing: '.2em', fontFamily: 'DM Sans', fontWeight: '700', fontStyle: 'normal' }}>{`${word} `}</span>;
                    })}
                </p>
            </Col>
            {designation &&
                <PTag value={designation} customStyle={{ fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', letterSpacing: '.2em', }} />
            }
        </Row>
        <Row style={{ paddingLeft: '20mm', paddingRight: '20mm' }}>
            <Col style={{ borderRightColor: 'rebeccapurple', borderRightWidth: 1, borderRightStyle: 'solid', display: 'table', paddingRight: 20 }}>
                <PTag value={address} customStyle={{ textAlign: 'right', fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', letterSpacing: '.15em', fontSize: 13 }} />
            </Col>
            <Col md="auto" style={{ borderRightColor: 'rebeccapurple', borderRightWidth: 1, borderRightStyle: 'solid', display: 'table' }}>
                <PTag value={phonenumber} customStyle={{ paddingLeft: 10, paddingRight: 10, fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', letterSpacing: '.15em', fontSize: 13 }} />
            </Col>
            <Col style={{ display: 'table', paddingLeft: 20, }} >
                <PTag value={email} customStyle={{ textAlign: 'left', fontFamily: 'DM Sans', fontWeight: '400', fontStyle: 'normal', letterSpacing: '.15em', fontSize: 13 }} />
            </Col>
        </Row>
        <HorizontalLine customStyle={{ paddingLeft: '20mm', paddingRight: '20mm' }} />
    </HoverParent>)
}

const PTag = ({ value, customStyle = undefined }) => {
    return <p class="textOverlapFix" style={{ color: secondaryColor, whiteSpace: 'nowrap', textAlign: 'center', verticalAlign: 'middle', display: 'table-cell', ...customStyle }}>{value}</p>
}

export default TemplateContactInfo