import { useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { isEmpty, isValidEmail } from '../../util/StringUtil';
import { useSendUserToken } from '../../ui/hooks/useSendUserToken';
import { useVerifyUserToken } from '../../ui/hooks/useVerifyUserToken';
import { useSaveUserResume } from '../../ui/hooks/useSaveUserResume';
import { saveResumeEvent } from '../../util/Analytics';


let TOKEN_NOT_FETCHED = 'TOKEN_NOT_FETCHED'
let TOKEN_FETCHED = 'TOKEN_FETCHED'

function ResumeDownloaderUploader(props) {

    const [userEmail, setUserEmail] = useState('');
    const [userToken, setUserToken] = useState('');
    const [tokenState, setTokenState] = useState(TOKEN_NOT_FETCHED);
    let { sendUserToken } = useSendUserToken()
    let { verifyToken } = useVerifyUserToken()
    const { saveResume } = useSaveUserResume();

    let { isDownloader, isUploader } = props
    let buttonName = undefined
    let pageTitle = undefined
    if (isDownloader) {
        pageTitle = 'Resume To be downloaded for'
        buttonName = 'Get My Resume'
    } else if (isUploader) {
        pageTitle = 'Resume To be uploaded for'
        buttonName = 'Upload My Resume'
    }

    const validateEmail = () => {
        let isValid = isValidEmail(userEmail)
        if (!isValid) {
            return
        }
        sendUserToken(userEmail)
        setTokenState(TOKEN_FETCHED)
    }

    const validateToken = async () => {
        let isValid = isValidEmail(userEmail)
        if (!isValid || isEmpty(userToken) || userToken.length != 5) {
            return
        }
        let response = await verifyToken(userEmail, userToken)
        if (response && response.status == 200) {
            if (isDownloader) {
                props.savePressed && props.savePressed(userEmail)
                saveResumeEvent(userEmail)
            } else if (isUploader) {
                await saveResume()
                props.savePressed && props.savePressed()
            }
        }
    }

    const _closeButton = () => {
        setUserEmail('')
        setUserToken('')
        setTokenState(TOKEN_NOT_FETCHED);
        props.onHide && props.onHide()
    }

    return (
        <Modal
            backdrop="static"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton
                onHide={_closeButton}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {pageTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup>
                    <Form.Control
                        required
                        placeholder="Enter your email ..."
                        aria-label="Enter your email ..."
                        aria-describedby="basic-addon1"
                        defaultValue={userEmail}
                        onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                </InputGroup>
                <InputGroup className='mt-2'>
                    {tokenState === TOKEN_FETCHED &&
                        <Col>
                            <Form.Control
                                required
                                placeholder="Enter your Token ..."
                                aria-label="Enter your Token ..."
                                aria-describedby="basic-addon1"
                                defaultValue={userToken}
                                onChange={(event) => { setUserToken(event.target.value) }}
                            />
                            <p style={{ fontSize: 10 }}>{"Please check your inbox or spam folder of your email."}</p>
                        </Col>
                    }
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={_closeButton} variant="primary">Close</Button>
                <Button onClick={tokenState === TOKEN_NOT_FETCHED ? validateEmail : validateToken} variant="primary">{tokenState === TOKEN_NOT_FETCHED ? 'Fetch User Token' : buttonName}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ResumeDownloaderUploader