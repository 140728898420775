import { networkError, apiError, successResp } from './coreutils/error/ResponseObjects';
import ApiConstant from './coreutils/ApiConstant';
import axios from 'axios';
// import curlirize from 'axios-curlirize';
// curlirize(axios);
// http://193.203.162.209:4000
// let BASE_URL = "http://193.203.162.209"
// let LOCAL_HOST = ""
// axios.defaults.baseURL = "http://localhost:4000/";
// axios.defaults.baseURL = "http://apiquickreusme.online/";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "http://localhost:4000/";

export const REQUEST_TYPE = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete"
}

function requestObject(data) {
    let configObject = {
        [ApiConstant.URL]: data[ApiConstant.URL],
        [ApiConstant.METHOD]: data[ApiConstant.METHOD],
        [ApiConstant.HEADERS]: data[ApiConstant.HEADERS],
        [ApiConstant.PARAMS]: data[ApiConstant.PARAMS],
        [ApiConstant.DATA]: data[ApiConstant.DATA],
        [ApiConstant.TIMEOUT]: 3 * 1000 * 60, // 3 minutes
    }
    return configObject
}

export async function axiosRequest(data) {
    try {
        let configObejct = requestObject(data)
        let response = await axios.request(configObejct);
        return successResp(response)
    } catch (error) {
        return error
    }
}