import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../../data/store/actions/ProgressActions';
import { BsCalendarEvent } from 'react-icons/bs';
import { EDUCATION_DETAIL_ROUTE } from '../../../AppRouter';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../../../component/appHeader';
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { addMoreWorkOrder, addWorkOrderCity, addWorkOrderCompany, addWorkOrderCountry, addWorkOrderDescription, addWorkOrderEndDate, addWorkOrderJobtitle, addWorkOrderStartDate, deleteWorkOrder, updateWorkDetail } from '../../../data/store/actions/ResumeActions';
import WorkOrderListing from './WorkOrderListing';
import AppEditor from '../../../component/editor';
import { DimensionContext } from '../../../util/DimensionContext';
import { HEADERS, INPUT_FIELDS, BUTTONS } from '../../../util/Constants';
function WorkDetail() {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isValid, setValidationStatus] = useState(false)
    const workInfo = useSelector((state) => state.resumeReducer.workInfo)
    const [shouldShowDescriptionModel, setDescriptionModelStatus] = useState(false)
    const [indexEditorToBeOpenedFor, setIndexEditorToBeOpenedFor] = useState(-1)
    const width = useContext(DimensionContext);

    useEffect(() => {
        dispatch(updateProgress(48))
    }, [])

    const setCompanyName = (value, index) => {
        dispatch(addWorkOrderCompany(value, index))
    }

    const setJobTitle = (value, index) => {
        dispatch(addWorkOrderJobtitle(value, index))
    }
    const setCity = (value, index) => {
        dispatch(addWorkOrderCity(value, index))
    }
    const setCountry = (value, index) => {
        dispatch(addWorkOrderCountry(value, index))
    }
    const setStartDate = (value, index) => {
        dispatch(addWorkOrderStartDate(value, index))
    }
    const setEndDate = (value, index) => {
        dispatch(addWorkOrderEndDate(value, index))
    }
    const setDescription = (value, index) => {
        dispatch(addWorkOrderDescription(value, index))
    }

    const setDescriptionModelStatusCallback = (value, indexEditorToBeOpenedFor) => {
        setDescriptionModelStatus(value)
        setIndexEditorToBeOpenedFor(indexEditorToBeOpenedFor)
    }

    const navigateToEducationDetailScreen = () => {
        navigate(EDUCATION_DETAIL_ROUTE)
    }

    const addMore = () => {
        dispatch(addMoreWorkOrder())
    }

    const deleteItem = (index) => {
        if (!index) return
        dispatch(deleteWorkOrder(index))
    }

    const goBack = () => {
        navigate(-1)
    }

    const onSubmit = (event) => {
        // const form = event.currentTarget;
        // setValidationStatus(!form.checkValidity());
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // } else {
        //     event.preventDefault();
        navigateToEducationDetailScreen()
        // }
    };

    return (
        <Container fluid className={width < 800 ? "p2" : "p-5"}>
            <Form
                // noValidate 
                // validated={isValid}
                onSubmit={onSubmit} >
                <AppHeader title={HEADERS.WORK_DETIALS} onBack={goBack} />
                {workInfo.map((item, index) => {
                    return <WorkOrderListing
                        companyName={item.companyName}
                        jobTitle={item.jobTitle}
                        city={item.city}
                        country={item.country}
                        startDate={item.startDate}
                        endDate={item.endDate}
                        description={item.description}
                        index={index}
                        deleteItem={deleteItem}
                        setCompanyName={setCompanyName}
                        setJobTitle={setJobTitle}
                        setCity={setCity}
                        setCountry={setCountry}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setDescriptionModelStatusCallback={setDescriptionModelStatusCallback}
                    />
                })}
                <Button className='ms-5' variant="primary" onClick={addMore}>
                    {BUTTONS.ADD_MORE}
                </Button>
            </Form>
            {shouldShowDescriptionModel && <AppEditor
                description={workInfo[indexEditorToBeOpenedFor]?.description}
                show={shouldShowDescriptionModel}
                savePressed={(value) => {
                    setDescription(value, indexEditorToBeOpenedFor)
                    setDescriptionModelStatus(false)
                }}
                onHide={() => { setDescriptionModelStatus(false) }}
            />}
        </Container >);
}

export default WorkDetail;