import Heading from "./Heading"
import './style.css';
import styles from "./style";
import sanitizeHtml from 'sanitize-html';
import { TEMPLATES } from "../../../../util/Constants";

function EducationView({ educationInfo }) {
    return (<>
        <Heading heading={TEMPLATES.EDUCATION} />
        {educationInfo && educationInfo.map((item) => {
            return <EducationItem
                title={item.university}
                description={item.description}
                city={item.city}
                startDate={item.startDate}
                endDate={item.endDate}
            />
        })}
    </>)
}

const EducationItem = ({ title, description, startDate, endDate, city }) => {
    return (<>
        <p style={{ ...styles.textStyle, margin: 0, fontWeight: 'bold', fontSize: 16 }}>{title}</p >
        <p className="mt-1" style={{ ...styles.textStyle, marginBottom: 10, padding: 0, fontWeight: 'normal', fontSize: 16 }} dangerouslySetInnerHTML={{ __html: description }} />
    </>)
}



export default EducationView