import { getUserResume } from "../../data/network/userresume";

export const useLoadUserResume = () => {

    async function loadResume(email) {
        let response = await getUserResume({
            email: email,
        })
        return response
    }
    return { loadResume }
}

