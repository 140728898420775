import Heading from "./Heading"
import './style.css';
import styles from "./style";
import { TEMPLATES } from "../../../../util/Constants";

function WorkExperienceView({ workInfo }) {
    return (<>
        <Heading heading={TEMPLATES.WORK_SUMMARY} customStyle={{ paddingTop: '15mm' }} />
        {workInfo && workInfo.map((item) => {
            return <WorkExperienceItem
                title={item.companyName}
                subtitle={item.jobTitle}
                description={item.description}
                startDate={item.startDate}
                endDate={item.endDate}
            />
        })}
    </>)
}

const WorkExperienceItem = ({ title, description, startDate, endDate, city, subtitle }) => {
    return (<>
        <p style={{ ...styles.textStyle, margin: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 16 }}>{title}</p >
        <p className="mt-1" style={{ ...styles.textStyle, marginBottom: 10, padding: 0, fontFamily: 'Roboto', fontWeight: 'normal', fontSize: 16 }} dangerouslySetInnerHTML={{ __html: description }} />
    </>)
}


export default WorkExperienceView